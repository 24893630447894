<template>
  <div
    class="input_video"
    style="
      width: 96%;
      margin-left: 2%;
      margin-top: 1.5%;
      min-height: 560px;
      padding-top: calc(10px);
      background-color: #fff;
    "
  >
    <div v-if="sourceVideoId">
      <div style=" margin: 0 auto" id="playerer"></div>
    </div>
    <div v-else style="text-align: center; margin-top: 100px; font-size: 16px">
      暂无视频
    </div>
  </div>
</template>
<script>
import { getVideoToken } from '@/api/businesscourse/video'

export default {
  data () {
    return {
      playerOptions: '',
      vidInfo: {},
      vodPlayerJs: 'https://player.polyv.net/script/player.js',
      player: null,
      token: null,
      sourceVideoId: null,
    }
  },
  props: {
    sourceId: {
      default: '',
      type: String,
    },
    type: {
      default: '',
      type: String,
    },
    linkId: {
      default: '',
      type: String,
    },
  },

  watch: {
    sourceId: {
      handler (val) {
        if (document.getElementById('playerer')) {
          document.getElementById('playerer').innerHTML = ''
        }
        if (val) {
          this.sourceVideoId = val
          this.$nextTick(() => {
            this.showVideo()
          })
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    showVideo () {
      const params = {
        sourceVideoId: this.sourceVideoId,
      }
      getVideoToken(params).then((res) => {
        if (res.state == 'success') {
          this.vidInfo = JSON.parse(res.body.playToken)
          this.$nextTick(() => {
            this.loadPlayerScript(this.loadPlayer)
          })
        }
      })
    },
    loadPlayerScript (callback) {
      if (!window.polyvPlayer) {
        const myScript = document.createElement('script')
        myScript.setAttribute('src', this.vodPlayerJs)
        myScript.onload = callback
        document.body.appendChild(myScript)
      } else {
        callback()
      }
    },

    loadPlayer () {
      let that = this
      const polyvPlayer = window.polyvPlayer
      this.player = polyvPlayer({
        wrap: '#playerer',
        // width: 800,
        height: document.documentElement.clientHeight - 180,
        vid: this.vidInfo.data.videoId,
        playsafe: this.vidInfo.data.token,
        code: '可达鸭编程—' + localStorage.getItem('userName') + '|' + localStorage.getItem('token_business')
      })
    },
    destroyed () {
      if (this.player) {
        this.player.destroy()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.button-m {
  margin-right: 10px;
}

.home {
  blockquote {
    padding: 10px 20px;
    margin: 0 0 20px;
    font-size: 17.5px;
    border-left: 5px solid #eee;
  }

  hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid #eee;
  }

  .col-item {
    margin-bottom: 20px;
  }

  ul {
    padding: 0;
    margin: 0;
  }

  font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
  color: #676a6c;
  overflow-x: hidden;

  ul {
    list-style-type: none;
  }

  h4 {
    margin-top: 0px;
  }

  h2 {
    margin-top: 10px;
    font-size: 26px;
    font-weight: 100;
  }

  p {
    margin-top: 10px;

    b {
      font-weight: 700;
    }
  }

  .update-log {
    ol {
      display: block;
      list-style-type: decimal;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0;
      margin-inline-end: 0;
      padding-inline-start: 40px;
    }
  }
}
</style>
