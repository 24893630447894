<template>
  <el-row>
    <el-table :data="dataList" border fit highlight-current-row class="tb-list"  style="max-height: 600px;overflow-y: scroll">
      <el-table-column align="center" label="姓名">
        <template slot-scope="scope">
          {{scope.row.name}}[{{scope.row.loginName}}]
        </template>
      </el-table-column>
      <el-table-column align="center" label="最高得分">
        <template slot-scope="scope">
          <el-row>
            <span v-if="scope.row.maxInDate !== null">{{scope.row.maxScore}}</span>
            <span v-else>0</span>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column align="center" label="提交状态" >
        <template slot-scope="scope">
          <el-row>
            <span :style="'color'+':'+ getStateColor(scope.row.maxInDate)" v-if="scope.row.maxInDate !== null">已提交</span>
            <span :style="'color'+':'+ getStateColor(scope.row.maxInDate)" v-else>未提交</span>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column align="center" label="最后提交时间">
        <template slot-scope="scope">
          <el-row>
            <span v-if="scope.row.maxInDate !== null">{{scope.row.maxInDate.substring(0,19)}}</span>
            <span v-else>无</span>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>
  </el-row>
</template>

<script>
import { getUserSumitLogByContest } from '@/apiteacher/teacher'
export default {
  props: {
    contest_id: {
      default: "",
      type: String
    },
    lesson_id: {
      default: "",
      type: String
    },
  },
  data() {
    return {
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0
      }
    };
  },
  created() {
    this.getSubmitHistory();
  },
  methods: {
    // 获取学生统计
    async getSubmitHistory() {
      const res = await getUserSumitLogByContest({
        needCount: true,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        contestId: this.contest_id,
        lessonId: this.lesson_id,
      })
      this.dataList = res.body.list;
      this.pagination.total = res.body.total;
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getSubmitHistory();
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.getSubmitHistory();
    },
    getStateColor(state) {
      switch (state) {
        case null:
          return "#CE433F";
        case '':
          return "#CE433F";
        case undefined:
          return "#CE433F";
          break;
        default:
          return "#0CA51A";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../style/table.scss";
</style>
