var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        width: "70%",
        top: "5vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [_c("i"), _c("span", [_vm._v("刷题记录")])]
      ),
      _c(
        "el-tabs",
        {
          staticStyle: { "margin-left": "20px" },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "提交记录", name: "first" } },
            [
              _c("sub-record", {
                attrs: { contest_id: _vm.contestId, lesson_id: _vm.lessonId },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "提交统计", name: "second" } },
            [
              _c("sub-rank", {
                attrs: { contest_id: _vm.contestId, lesson_id: _vm.lessonId },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }