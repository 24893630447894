<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="70%"
    @open="openDialog"
    append-to-body
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>试卷批阅</span>
    </div>
    <el-tabs v-model="activeName" style="margin-left: 16px;">
      <el-tab-pane label="提交统计" name="first">
        <el-table
          :data="dataList"
          style="margin-top: 16px;"
          border
          fit
          highlight-current-row
          ref="dataList"
        >
          <el-table-column label="试卷名" align="center" prop="examTitle"></el-table-column>
          <el-table-column label="学员" align="center">
            <template slot-scope="scope">
              <span>{{[scope.row.loginName]}}{{scope.row.userName}}</span>
            </template>
          </el-table-column>
          <el-table-column label="最高得分" align="center" prop="topScore">
            <template slot-scope="scope">
              <span v-if="scope.row.topScore">{{scope.row.topScore}}</span>
              <span v-else>暂无</span>
            </template>
          </el-table-column>
          <el-table-column label="提交状态" align="center">
            <template slot-scope="scope">
              <span v-if="!scope.row.lastFinishTime">未提交</span>
              <span v-else>已提交</span>
            </template>
          </el-table-column>
          <el-table-column label="最后提交时间" align="center" prop="lastFinishTime"></el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.currentPage"
          :page-sizes="pagination.pageSizes"
          :page-size="pagination.pageSize"
          :layout="pagination.layout"
          :total="this.pagination.total"
        ></el-pagination>
      </el-tab-pane>
      <el-tab-pane label="提交记录" name="second">
        <el-row style="margin-top: 16px;">
          <el-input placeholder="请输入学号" v-model="stuNo" class="tb-sm-input" @keyup.enter.native="getListExamUserByLesson"></el-input>
          <el-input placeholder="请输入学生姓名" v-model="stuName" class="tb-sm-input" @keyup.enter.native="getListExamUserByLesson"></el-input>
          <el-button type="primary" size="medium" @click="getListExamUserByLesson" class="tb-button">搜索</el-button>
          <el-button type="info" size="medium" @click="reset" class="tb-button">重置</el-button>
        </el-row>
        <el-table
          :data="dataList2"
          border
          fit
          highlight-current-row
          ref="dataList2"
        >
          <el-table-column label="试卷名" align="center" prop="examTitle"></el-table-column>
          <el-table-column label="学员" align="center">
            <template slot-scope="scope">
              <span>{{[scope.row.loginName]}}{{scope.row.userName}}</span>
            </template>
          </el-table-column>
          <el-table-column label="得分" align="center">
            <template slot-scope="scope">
              <span class="title-link" @click="previewPaperResult(scope.row)">{{scope.row.topScore}}</span>
            </template>
          </el-table-column>
          <el-table-column label="批阅状态" align="center">
            <template slot-scope="scope">
              <span v-if="Boolean(!scope.row.lastFinishTime)">未提交</span>
              <span
                v-else-if="Boolean(!scope.row.reviewTime)"
                class="title-link"
                @click="previewPaperResult(scope.row)"
              >未批阅</span>
              <span v-else class="title-link" @click="previewPaperResult(scope.row)">已批阅</span>
            </template>
          </el-table-column>
          <el-table-column label="发布结果" align="center" prop="isAnnounce">
            <template slot-scope="scope">
              <span v-if="scope.row.isAnnounce === true">已发布</span>
              <span v-else>未发布</span>
            </template>
          </el-table-column>
          <el-table-column label="提交时间" align="center" prop="lastFinishTime"></el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange2"
          @current-change="handleCurrentChange2"
          :current-page="pagination2.currentPage"
          :page-sizes="pagination2.pageSizes"
          :page-size="pagination2.pageSize"
          :layout="pagination2.layout"
          :total="this.pagination2.total"
        ></el-pagination>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script>
import { listExamUserStatByLesson, listExamUserByLesson,getTaskInfo } from '@/apiteacher/teacher'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    taskID: {
      default: "",
      type: String
    },
    lessonId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      title: '',
      dataList: [],
      dataList2: [],
      activeName: 'first',
      stuNo: '',
      stuName: '',
      sourceId: '', // 试卷id
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      pagination2: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      }
    }
  },
  methods: {
    openDialog () {
      this.stuNo = ''
      this.stuName = ''
      // console.log(JSON.stringify(this.examData));
      // this.dataList = this.examData;
      this.getTaskInfo()
    },
    // 关闭
    close () {
      this.$emit('close')
    },

    reset () {
      this.stuNo = ''
      this.stuName = ''
    },

    // 通过任务id获取考试id
    getTaskInfo () {
      getTaskInfo({
        id: this.taskID
      }).then(res => {
        if (res.state === 'success') {
          this.sourceId = res.body.sourceId
          this.getListExamUserStatByLesson()
          this.getListExamUserByLesson()
        }
      })
    },

    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getListExamUserStatByLesson()
    },
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getListExamUserStatByLesson()
    },

    handleCurrentChange2 (val) {
      this.pagination2.currentPage = val
      this.getListExamUserByLesson()
    },
    handleSizeChange2 (val) {
      this.pagination2.pageSize = val
      this.getListExamUserByLesson()
    },

    getListExamUserStatByLesson () {
      listExamUserStatByLesson({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        lessonId: this.lessonId,
        examId: this.sourceId
      }).then(res => {
        if (res.state === 'success') {
          this.dataList = res.body.list
          this.pagination.total = res.body.total
        }
      })
    },

    getListExamUserByLesson () {
      listExamUserByLesson({
        pageNum: this.pagination2.currentPage,
        pageSize: this.pagination2.pageSize,
        lessonId: this.lessonId,
        examId: this.sourceId,
        stuNo: this.stuNo,
        stuName: this.stuName
      }).then(res => {
        if (res.state === 'success') {
          this.dataList2 = res.body.list
          this.pagination2.total = res.body.total
        }
      })
    },
    // 查看试卷详情
    previewPaper (row) {
      // this.close()
      let route = this.$router.resolve({
        path: '/research/exam-details',
        query: {
          examID: row.examId
        }
      })
      window.open(route.href, '_blank')
    },

    // 查看学生试卷结果
    previewPaperResult (row) {
      console.log('row', row)
      // this.close()
      let routeUrl = this.$router.resolve({
        path: '/teacher/paper-result',
        query: {
          id: row.euId,
          // id: row.examId,
          userId: row.userId
        }
      })
      window.open(routeUrl.href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/dialog.scss";
</style>
