<template>
  <div>
    <div class="parentScroll" :style="{ height: autoHeight + 'px' }">
      <el-card shadow="nover" v-if="updownFlag">
        <div class="headertop">
          <span class="titleName">
            {{ courseTitle }}
          </span>
          <div
            style="
              float: right;
              margin: 15px;
              display: flex;
              align-items: center;
            "
          >
            <div
              style="display: flex; align-items: center; margin-right: 100px"
            >
              互动课堂：
              <div v-if="onlineState">
                <span class="span-stategreen"></span>
                <span style="color: #4da54d">在线</span>
              </div>
              <div v-if="!onlineState">
                <span class="span-statered"></span>
                <span style="color: #ce433f">离线</span>
                <el-button
                  v-if="handfix && courseStatus === '正在上课'"
                  class="el-icon-s-tools"
                  size="small"
                  style="font-size: 14px; margin-left: 15px"
                  type="primary"
                  @click="againInitAgora"
                  >手动修复
                </el-button>
              </div>
            </div>
            <el-button
              size="mini"
              type="info"
              style="margin-right: 40px"
              @click="drawer = true"
              >学员状态
            </el-button>
            <el-button
              size="mini"
              style="margin-right: 50px"
              :type="currentType"
              @click="attendLesson"
            >
              {{ courseStatusText }}
            </el-button>
          </div>
        </div>
      </el-card>
      <div
        v-if="courseType === '视频'"
        style="width: 100%; display: flex; justify-content: center"
      >
        <i
          :class="!updownFlag ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
          style="font-size: 20px; cursor: pointer"
          @click="upDownClick"
        ></i>
      </div>
      <el-row style="margin-top: 20px" v-if="courseType !== '视频'">
        <el-col :span="4" v-if="!pickLeftFlag">
          <el-card
            :style="{ height: autoHeight * 0.87001 + 'px' }"
            style="overflow-y: scroll"
          >
            <div
              class="titleName"
              style="font-size: 15px; margin-top: 10px; margin-bottom: 10px"
            >
              任务列表
              <i
                class="el-icon-s-fold"
                style="float: right; font-size: 18px"
                @click="pickupLeft"
              ></i>
            </div>
            <div v-for="(item, index) in taskListArray" :key="index">
              <div
                class="taskitem"
                style="cursor: pointer"
                @click="changeTask(item.id, index, item)"
              >
                <span> 任务{{ index + 1 }} {{ item.title }} </span>
                <el-row>
                  <span class="customBut">{{ item.type }}</span>
                  <span class="customButone" v-if="item.practicePurpose === 'Y'"
                    >课堂练习</span
                  >
                  <span class="customButtwo" v-if="item.learnPurpose === 'Y'"
                    >学员可用</span
                  >
                </el-row>
                <div class="line"></div>
              </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="0.5" v-else style="margin-top: 20px">
          <i
            class="el-icon-s-unfold"
            style="font-size: 18px"
            @click="spreadOutLeft"
          ></i>
        </el-col>
        <el-col :span="middleWidth" style="margin-left: 20px">
          <el-card style="line-height: 50px">
            <span>任务{{ currentIndex }}</span>
            <span style="margin-left: 15px">{{ taskInfo.title }}</span>
            <span class="customBut" style="margin-left: 15px">
              {{ taskInfo.type }}</span
            >
            <span class="customButone" v-if="taskInfo.practicePurpose === 'Y'"
              >课堂练习</span
            >
            <span class="customButtwo" v-if="taskInfo.learnPurpose === 'Y'"
              >学员可用</span
            >
            <span v-if="sourceContent !== ''" style="float: right"
              ><i
                class="el-icon-full-screen"
                size="24"
                @click="previewCourse"
              ></i
            ></span>
          </el-card>

          <el-card style="line-height: 50px; margin-top: 10px">
            <el-tabs v-model="activeName">
              <el-tab-pane label="课件" name="first">
                <el-row>
                  <el-button size="mini" type="primary" @click="pushCourseWare">
                    推送到大屏
                  </el-button>
                </el-row>
                <div
                  v-if="sourceContent !== '' && sourceContent.indexOf('/') > -1"
                >
                  <div
                    :style="{ height: autoHeight * 0.61412 + 'px' }"
                    id="waterMaskId"
                  >
                    <iframe
                      :src="sourceContent"
                      allowfullscreen="true"
                      style="width: 100%; height: 100%"
                    ></iframe>
                  </div>
                </div>
                <div
                  style="width: 100%; height: 100%; overflow: auto"
                  v-show="
                    sourceContent !== '' && sourceContent.indexOf('/') == -1
                  "
                >
                  <LookPpt
                    @changeTaskByChild="changeTaskByChild"
                    ref="pptIframeg"
                    :content="sourceContent"
                  />
                </div>
                <div v-show="!sourceContent">
                  <div
                    :style="{ height: autoHeight * 0.1 + 'px' }"
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      font-size: 14px;
                    "
                  >
                    暂无课件
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane
                label="任务详情"
                v-if="taskInfo.type != '课件'"
                name="second"
              >
                <div style="padding: 20px; padding-left: 0px">
                  <el-button
                    size="mini"
                    type="primary"
                    @click="pushTaskToscreen"
                    >推送到大屏
                  </el-button>
                  <el-button
                    size="mini"
                    type="primary"
                    v-if="questionList.length == 0"
                    @click="pushTaskToStudent"
                    >推送到学员
                  </el-button>
                  <el-dropdown
                    style="margin: 0 10px"
                    @command="pushTaskToStudentBefore"
                    v-else
                  >
                    <el-button size="mini" type="primary">推送到学员</el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        :command="i"
                        v-for="(i, j) in questionList"
                        :key="j"
                      >
                        {{ i.problemName }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <el-button
                    v-show="
                      taskInfo.type == 'scratch' ||
                      taskInfo.type == 'goc' ||
                      taskInfo.type == 'python' ||
                      taskInfo.type == '测试' ||
                      taskInfo.type == '编程'
                    "
                    size="mini"
                    type="info"
                    @click="gotoPreviewRecord"
                    >查看提交记录
                  </el-button>
                  <!--                  <i-->
                  <!--                    class="el-icon-full-screen"-->
                  <!--                    size="24"-->
                  <!--                    @click="previewTasks"-->
                  <!--                  ></i>-->
                  <div style="height: calc(100%)">
                    <previewTaskShow
                      v-if="
                        showTaskFlag === 1 &&
                        currenttaskID &&
                        taskInfo.type !== 'Pygame' &&
                        taskInfo.type !== 'scratchjr'
                      "
                      @synchroIndex="synchroIndex"
                      @asyncproblemId="asyncproblemId"
                      :style="{ height: autoHeight * 0.66 + 'px' }"
                      :taskID="currenttaskID"
                    />
                    <pdfpreviewShow
                      v-if="showTaskFlag === 2 && currenttaskID"
                      :style="{ height: autoHeight * 0.66 + 'px' }"
                      style="width: 100%; overflow-y: scroll"
                      :taskID="currenttaskID"
                    />
                    <previewVideoShow
                      v-if="showTaskFlag === 3"
                      :sourceId="taskInfo.sourceId"
                    />
                    <previewPygameShow
                      :style="{
                        height: autoHeight * 0.66 + 'px',
                        overflowY: 'auto',
                      }"
                      v-if="taskInfo.type === 'Pygame'"
                      :sourceId="taskInfo.sourceId"
                      :taskInfo="taskInfo"
                    />
                    <previewScratchJrShow
                      :style="{
                        height: autoHeight * 0.66 + 'px',
                        overflowY: 'auto',
                      }"
                      v-if="taskInfo.type === 'scratchjr'"
                      :sourceId="taskInfo.sourceId"
                      :taskInfo="taskInfo"
                    />
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="说课视频" name="third">
                <div class="titleName" style="font-size: 14px; padding: 15px 0">
                  <i
                    class="el-icon-full-screen"
                    v-if="status !== ''"
                    style="font-size: 18px; margin-right: 10px"
                    @click="previewVideo"
                  ></i>
                </div>
                <div v-if="status !== ''">
                  <div style="margin: 0 auto" id="player1"></div>
                </div>
                <div v-else class="emptyVideo">暂无视频</div>
              </el-tab-pane>
            </el-tabs>
          </el-card>
        </el-col>
        <el-col
          :span="rightauto"
          style="margin-left: 20px"
          v-if="!pickRightFlag"
        >
          <el-card
            style="line-height: 30px; padding: 10px 0px; overflow-y: auto"
            :style="{ height: autoHeight * 0.87001 + 'px' }"
          >
            <el-row>
              <span class="titleName" style="font-size: 14px">教案</span>
              <i
                class="el-icon-s-unfold"
                style="float: right; font-size: 18px; margin-left: 15px"
                @click="pickupRight"
              ></i>
            </el-row>
            <div>
              <div class="jiaoantitle">【课堂教案】</div>
              <div id="result" class="jiaoancontent"></div>
              <div class="jiaoantitle">【备课教案】</div>
              <div v-if="readyPlan" class="jiaoancontent">
                {{ readyPlan }}
              </div>
              <div v-else class="jiaoancontent">暂无备课教案</div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="0.5" v-else style="margin-top: 20px">
          <i
            class="el-icon-s-fold"
            style="font-size: 18px"
            @click="spreadOutRight"
          ></i>
        </el-col>
      </el-row>
      <el-row v-else-if="courseType === '视频' && courseStatus === '正在上课'">
        <div>
          <preview-video-modle
            ref="previewvideomodle"
            :sourceId="linkInfo.content"
            :type="linkInfo.type"
            :linkId="linkInfo.id"
            @sendToStudnet="sendToStudnet"
            @finishSendToStudnet="finishSendToStudnet"
          ></preview-video-modle>
        </div>
        <div class="rightflod" v-if="flodFlag" @click="flodFlag = false">
          <i class="el-icon-arrow-right" style="font-size: 12px"></i>
          <div>收</div>
          <div style="margin-top: 3px; margin-bottom: 3px">起</div>
        </div>
        <div class="rightzhan" v-if="!flodFlag" @click="flodFlag = true">
          <i class="el-icon-arrow-left" style="font-size: 12px"></i>
          <div>展</div>
          <div style="margin-top: 3px; margin-bottom: 3px">开</div>
        </div>
        <div class="rightParent" v-if="flodFlag">
          <el-menu class="rightContent">
            <el-tooltip
              class="item"
              effect="dark"
              content="上一个"
              placement="left"
            >
              <el-menu-item index="1" @click="preCourseTask">
                <i style="font-size: 18px" class="el-icon-top"></i>
              </el-menu-item>
            </el-tooltip>
            <el-menu-item index="2">
              <el-popover
                placement="left"
                width="380"
                trigger="hover"
                style="margin-top: 15px"
              >
                <div>
                  <span style="font-weight: bold; font-size: 14px"
                    >环节列表</span
                  >
                  <ul
                    style="font-size: 14px; line-height: 35px; color: #222222"
                  >
                    <div
                      style="cursor: pointer"
                      v-for="(item, index) in linkList"
                      :key="index"
                    >
                      <i
                        class="el-icon-data-board"
                        style="font-size: 18px; margin-right: 15px"
                      ></i>
                      <span>{{ item.title }}</span>
                      <li
                        style="margin-left: 20px; font-size: 14px"
                        v-if="item.misslesonVideo"
                        v-for="(value, index) in item.misslesonVideo"
                        :key="index"
                      >
                        <span
                          :class="
                            currentLinkId === value.id ? 'kejianactive' : ''
                          "
                          @click="changeLinkVideo(value, index)"
                        >
                          {{ value.title }}
                        </span>
                      </li>
                    </div>
                  </ul>
                </div>
                <img
                  slot="reference"
                  style="width: 21px; height: 21px; margin-left: 2px"
                  src="../../assets/images/list.png"
                />
              </el-popover>
            </el-menu-item>
            <el-tooltip
              class="item"
              effect="dark"
              content="下一个"
              placement="left"
            >
              <el-menu-item index="3" @click="nextCourseTask">
                <i style="font-size: 18px" class="el-icon-bottom"></i>
              </el-menu-item>
            </el-tooltip>
            <el-menu-item index="4">
              <el-popover
                placement="left"
                width="380"
                trigger="hover"
                style="margin-top: 15px"
              >
                <div>
                  <span style="font-weight: bold; font-size: 14px"
                    >任务列表</span
                  >
                  <ul
                    style="font-size: 14px; line-height: 35px; color: #222222"
                  >
                    <li
                      style="cursor: pointer"
                      v-for="(item, index) in videoList"
                      :class="currentValue === index ? 'kejianactive' : ''"
                      @click="changeTaskClick(item.id, index, item)"
                      :key="index"
                    >
                      <i
                        class="el-icon-document"
                        style="font-size: 14px; margin-right: 15px"
                      ></i
                      >{{ item.title }}
                    </li>
                  </ul>
                </div>
                <i
                  slot="reference"
                  style="font-size: 18px"
                  class="el-icon-document"
                ></i>
              </el-popover>
            </el-menu-item>
          </el-menu>
        </div>

        <el-dialog
          :visible.sync="
            currenttaskID && videoList.length !== 0 && showTaskPage
          "
          top="3vh"
          width="80%"
          :before-close="close"
        >
          <el-button
            v-if="pushTaskId === currenttaskID"
            type="danger"
            size="mini"
            style="margin-left: 20px"
            @click="sendNotasktoStudent()"
            >结束推送
          </el-button>
          <el-button
            v-else
            type="primary"
            size="mini"
            style="margin-left: 20px"
            @click="changeTask(linkVideoTaskId, linkVideoIndex, linkVideoItem)"
            >推送给学员
          </el-button>
          <previewTaskShow
            v-if="showTaskFlag === 1 && currenttaskID"
            @synchroIndex="synchroIndex"
            @asyncproblemId="asyncproblemId"
            :style="{ height: autoHeight - 60 + 'px' }"
            :taskID="currenttaskID"
          />
          <pdfpreviewShow
            v-if="showTaskFlag === 2 && currenttaskID"
            style="width: 100%; overflow-y: scroll"
            :taskID="currenttaskID"
          />
          <preview-video-task
            ref="previewVideo"
            v-if="showTaskFlag === 3"
            :sourceId="taskInfo.sourceId"
          />
          <previewPygameShow
            style="height: calc(100vh - 100px); overflow-y: auto"
            v-if="showTaskFlag === 4"
            :sourceId="taskInfo.sourceId"
            :taskInfo="taskInfo"
          />
          <previewScratchJrShow
            style="height: calc(100% - 100px); overflow-y: auto"
            v-if="showTaskFlag === 5"
            :sourceId="taskInfo.sourceId"
            :taskInfo="taskInfo"
          />
        </el-dialog>
        <el-row
          style="text-align: center; margin-top: 18%"
          v-if="videoList.length === 0"
        >
          暂无任务
        </el-row>
      </el-row>
      <el-row v-else-if="courseStatus === '未上课'">
        <el-row style="text-align: center; margin-top: 18%">
          未上课状态不能查看任务
        </el-row>
      </el-row>
      <el-row v-else-if="courseStatus === '已上课'">
        <el-row style="text-align: center; margin-top: 18%">
          已上课状态不能查看任务
        </el-row>
      </el-row>
    </div>
    <el-drawer title="我是标题" :visible.sync="drawer" :with-header="false">
      <div style="padding: 15px">
        <div class="titleName" style="font-size: 14px; margin-top: 15px">
          学员列表
        </div>
        <div style="margin-top: 15px; overflow-y: auto">
          <div v-for="(item, index) in lessonStudentList" :key="index">
            <el-row class="studentitem">
              <el-col :span="10">
                <span> {{ item.name }}[{{ item.loginName }}] </span>
              </el-col>
              <el-col :span="6">
                <span class="customButtwo" v-if="item.isAttend === '是'">
                  已签到
                </span>
                <span class="customButone" v-else> 未签到 </span>
              </el-col>
              <el-col :span="6">
                <span
                  class="customButtwo"
                  style="margin-left: 15px"
                  v-if="item.isLogin === '是'"
                >
                  在线
                </span>
                <span class="customButone" style="margin-left: 15px" v-else>
                  离线
                </span>
              </el-col>
            </el-row>
          </div>
          <div style="margin-top: 50px">
            <el-button
              type="primary"
              size="small"
              @click="openStudentTask"
              v-if="currentMenuState === 'Y'"
              >关闭目录
            </el-button>
            <el-button
              type="primary"
              size="small"
              @click="openStudentTask"
              v-if="currentMenuState === 'N' || currentMenuState === null"
              >显示目录
            </el-button>
            <el-tooltip
              class="item"
              effect="dark"
              content="当无法给学员推送任务且无法修复互动课堂时，可以给学员显示任务目录，使学员可自主打开任务内容"
              placement="right"
            >
              <i
                class="el-icon-question"
                style="font-size: 18px; margin-left: 10px"
              ></i>
            </el-tooltip>
          </div>
        </div>
      </div>
    </el-drawer>
    <summitrecord
      :show="recordDialog"
      @close="recordDialog = false"
      :taskID="currenttaskID"
      :lessonId="lessonId"
    ></summitrecord>
    <pythonRecord
      :show="pythonRecordDialog"
      @close="pythonRecordDialog = false"
      :currenttype="taskInfo.type"
      :lessonId="lessonId"
      :taskId="currenttaskID"
    ></pythonRecord>
    <scratchjrRecord
      :show="scratchjrRecordDialog"
      @close="scratchjrRecordDialog = false"
      :currenttype="taskInfo.type"
      :lessonId="lessonId"
      :taskId="currenttaskID"
    ></scratchjrRecord>
    <scratchRecord
      :show="scratchRecordDialog"
      @close="scratchRecordDialog = false"
      :taskID="currenttaskID"
      :classID="classId"
      :lessonId="lessonId"
    ></scratchRecord>
    <commitrecord
      :show="OjRecordDialog"
      @close="OjRecordDialog = false"
      :contestId="taskInfo.sourceId"
      :lessonId="lessonId"
    ></commitrecord>
  </div>
</template>

<script>
import AgoraRTM from "agora-rtm-sdk";
import commitrecord from "@/componentsTeacher/commit-record";
import summitrecord from "@/componentsTeacher/marking-exampaper";
import pythonRecord from "@/componentsTeacher/python-record";
import scratchRecord from "@/componentsTeacher/preview-scratch";
import scratchjrRecord from "@/componentsTeacher/scratchjr-record";
import {
  getQuestionList,
  taskList,
  listAttachment,
  taskprepareGetInfo,
  taskprepareEdit,
  getAgoraToken,
  attendLesson,
  getLessonStudentInfoByIdWithPage,
  finishLesson,
  rtmDayLog,
  courseUnitSwitch,
  getCourseUnitState,
  getLessonFinishedStatus,
  saveLastPush,
} from "@/apiteacher/teacher";
import LookPpt from "@/views/PPT/look";

import previewTaskShow from "@/componentsTeacher/previewTask";
import pdfpreviewShow from "@/componentsTeacher/pdfpreview";
import previewVideoShow from "@/componentsTeacher/previewVideo/indexs";
import previewVideoModle from "@/componentsTeacher/previewVideo/videoindex";
import previewVideoTask from "@/componentsTeacher/previewVideo/videotask";
import previewPygameShow from "@/views/Pygame/pregive";
import previewScratchJrShow from '@/views/ScratchJr/lookd'

import { getVideoToken } from "@/api/businesscourse/video";

export default {
  components: {
    summitrecord,
    pythonRecord,
    scratchjrRecord,
    scratchRecord,
    commitrecord,
    previewTaskShow,
    pdfpreviewShow,
    previewVideoShow,
    previewVideoModle,
    previewVideoTask,
    LookPpt,
    previewPygameShow,
    previewScratchJrShow,
  },
  mounted() {
    window.addEventListener("resize", this.changeSize);
    this.classId = this.$route.query.classId;
    this.courseTitle = this.$route.query.courseTitle;
    this.lessonId = this.$route.query.lessonId;
    this.courseType = this.$route.query.courseType;

    this.getLessonStudentInfoByIdWithPage();
    this.getCourseUnitState();
    // document.addEventListener('visibilitychange', this.visibilityChangeHandler)
    this.getLessonFinishedStatus();
    // console.log(this.autoHeight)
    this.rightauto = parseInt(
      400 / (document.documentElement.clientWidth / 24)
    );
    // console.log(JSON.stringify(this.rightauto))
    this.middleWidth = 19 - this.rightauto;
    this.autoHeight = document.documentElement.clientHeight;

    if (sessionStorage.getItem("courseStatus") === "正在上课") {
      if (this.courseStatus === "未上课") {
        this.getTmsToken();
      }
      this.courseStatusText = "正在上课";
      this.courseStatus = "正在上课";
      this.onlineState = true;
      this.typeshow("正在上课");
    } else if (sessionStorage.getItem("courseStatus") === "已上课") {
      this.courseStatusText = "已上课";
      this.courseStatus = "已上课";
      this.typeshow("已上课");
    }

    this.taskLeftList();
    this.$nextTick(() => {
      this.$watermark.set("可达鸭编程", "waterMaskId");
    });
  },
  watch: {
    "taskInfo.teacherPlan": {
      handler(val) {
        if (document.getElementById("result")) {
          if (val) {
            document.getElementById("result").innerText = val;
          } else {
            document.getElementById("result").innerText = "<p>暂无课堂教案</p>";
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      flodFlag: true,
      currentTaskListInfo: {},
      currentProblemId: "0",
      pythonorScratchIndex: 0, // python或者scratch推送的index
      currentMenuState: null, // 'Y'标识当前课节开启了学生端目录功能，'N'标识未开启
      realInterrupte: false, // 5秒后如果这个值为true说明真的掉线了，自动重连没有成功
      onlineState: false,
      handfix: false,
      drawer: false,
      rightauto: 5,
      currentType: "primary",
      readyPlan: "", //备课教案
      courseStatus: "", //上课状态
      courseStatusText: "",
      middleWidth: 14,
      pickRightFlag: false,
      pickLeftFlag: false,
      recordDialog: false,
      lessonStudentList: [],
      lessonId: "",
      courseTitle: "",
      sourceContent: "",
      onlinePpt: "N",
      activeNameTab: "first",
      currentIndex: 0,
      autoHeight: 0,
      taskListArray: [],
      textarea: "",
      activeName: "first",
      status: "",
      // optionsVideo: [],
      currenttaskID: "",
      pushTaskId: "",
      showTaskFlag: 1,
      teacherPlan: "",
      taskInfo: {},
      globalClient: null,
      globalChannel: null,
      currentPushTaskId: "",
      pythonRecordDialog: false,
      scratchjrRecordDialog: false,
      scratchRecordDialog: false,
      OjRecordDialog: false,
      classId: "",
      questionList: [], // 记录编程列表
      problemId: "", // 记录问题 ID

      vidInfo2: null,
      vodPlayerJs: "https://player.polyv.net/script/player.js",
      player: null,
      videoEleId: "#player1",

      videoList: [],
      linkList: [],
      courseType: "",
      currentLinkValue: "",
      currentValue: 0,
      updownFlag: true,

      linkInfo: null,
      currentLinkId: "",
      showTaskPage: false,

      linkVideoTaskId: "",
      linkVideoIndex: "",
      linkVideoItem: null,

      currentVideoValue: "",
      VideoValueList: [],
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.globalClient !== null) {
      this.globalClient.logout();
    }
    if (this.globalChannel !== null) {
      this.globalChannel.leave();
    }
    this.rtmDayLog("老师下线", "老师退出当前路由页面");
    next();
  },
  destroyed() {
    window.removeEventListener("resize", this.changeSize);
    window.removeEventListener("beforeunload", (e) =>
      this.beforeunloadHandler(e)
    );
    window.removeEventListener("unload", (e) => this.unloadHandler(e));
  },
  methods: {
    changeSize() {
      this.autoHeight = document.documentElement.clientHeight;
      if (!this.updownFlag) {
        $("#playervideo .pv-video-player").height(
          document.documentElement.clientHeight * 0.95
        );
      } else {
        $("#playervideo .pv-video-player").height(
          document.documentElement.clientHeight * 0.89
        );
      }
    },
    changeLinkVideo(item, index) {
      this.VideoValueList.forEach((value, currentIndex) => {
        if (item.id === value.id) {
          this.currentVideoValue = currentIndex;
        }
      });
      this.linkInfo = item;
      this.currentLinkId = item.id;
    },
    upDownClick() {
      this.updownFlag = !this.updownFlag;
      if (!this.updownFlag) {
        $("#playervideo .pv-video-player").height(
          document.documentElement.clientHeight * 0.95
        );
      } else {
        $("#playervideo .pv-video-player").height(
          document.documentElement.clientHeight * 0.89
        );
      }
    },
    preCourseTask() {
      this.currentVideoValue--;
      this.$forceUpdate();
      if (this.currentVideoValue < 0) {
        this.currentVideoValue = 0;
        window.$msg("已经是第一个视频", 2);
        return false;
      } else {
        let item = this.VideoValueList[this.currentVideoValue];
        this.linkInfo = item;
        this.currentLinkId = item.id;
      }
    },
    nextCourseTask() {
      this.currentVideoValue++;
      this.$forceUpdate();
      if (this.currentVideoValue > this.VideoValueList.length - 1) {
        this.currentVideoValue = this.VideoValueList.length - 1;
        window.$msg("已经是最后一个视频", 2);
        return false;
      } else {
        let item = this.VideoValueList[this.currentVideoValue];
        this.linkInfo = item;
        this.currentLinkId = item.id;
      }
    },
    gotoTask(index) {
      this.currentValue = index;
      this.previewTask();
    },
    visibilityChangeHandler() {
      const visibility = document.visibilityState;
      if (visibility === "visible") {
        this.getLessonFinishedStatus();
      }
    },
    asyncproblemId(data) {
      this.currentProblemId = data.problemId;
      this.pushTaskToStudent();
    },
    async saveLastPush(content) {
      await saveLastPush({ lessonId: this.lessonId, content: content });
    },
    synchroIndex(data) {
      this.pythonorScratchIndex = data.index;
    },
    async getLessonFinishedStatus() {
      const res = await getLessonFinishedStatus({
        lessonId: this.lessonId,
      });
      if (res.state === "success") {
        this.courseStatus = res.body;
        if (res.body === "正在上课") {
          this.courseStatusText = "正在上课";
          this.currentType = "success";
          this.getTmsToken();
        } else if (this.courseStatus === "未上课") {
          this.courseStatusText = "开始上课";
        } else if (this.courseStatus === "已上课") {
          this.courseStatusText = "已上课";
          this.currentType = "warning";
        }
      }
    },
    async getCourseUnitState() {
      const res = await getCourseUnitState({
        lessonId: this.lessonId,
      });
      if (res.state === "success") {
        this.currentMenuState = res.body;
      }
    },
    async courseUnitSwitch() {
      const res = await courseUnitSwitch([
        {
          lessonId: this.lessonId,
          state:
            this.currentMenuState === null
              ? "Y"
              : this.currentMenuState === "N"
              ? "Y"
              : "N",
        },
      ]);
      if (res.state === "success") {
        if (this.currentMenuState === "Y") {
          this.currentMenuState = "N";
          window.$msg("已关闭学生端目录功能，请通知学生刷新学生端页面");
        } else if (
          this.currentMenuState === "N" ||
          this.currentMenuState === null
        ) {
          this.currentMenuState = "Y";
          window.$msg("已开启学生端目录功能，请通知学生刷新学生端页面");
        }
        this.$forceUpdate();
      }
    },
    async rtmDayLog(eventCode, desp) {
      rtmDayLog({
        lessonId: this.lessonId,
        source: "教师端",
        eventCode: eventCode,
        desp: desp,
      });
    },
    beforeunloadHandler() {
      this._beforeUnload_time = new Date().getTime();
    },
    unloadHandler(e) {
      this._gap_time = new Date().getTime() - this._beforeUnload_time;
      //判断是窗口关闭还是刷新
      if (this._gap_time <= 5) {
        //关闭当前页面
        this.$watermark.set("");
        if (this.globalChannel !== null) {
          this.globalChannel.leave();
        }
        if (this.globalClient !== null) {
          this.globalClient.logout();
        }
        this.rtmDayLog("老师下线", "老师关闭浏览器");
      } else {
        //刷新当前页面
      }
    },
    close() {
      this.showTaskPage = false;
      if (this.taskInfo.type === "视频") {
        if (this.$refs.previewVideo.player) {
          this.$refs.previewVideo.player.j2s_pauseVideo();
        }
      }
    },
    againInitAgora() {
      this.$confirm("确定要手动修复推送任务功能吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        closeOnClickModal: false,
        closeOnPressEscape: false,
      })
        .then(() => {
          this.rtmDayLog("老师修复", "老师手动调用了修复");
          this.getTmsToken();
        })
        .catch(() => {});
    },
    openStudentTask() {
      this.$confirm(
        this.currentMenuState === "Y"
          ? "确定要关闭学生端目录功能吗？"
          : "确定要打开学生端目录功能吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          closeOnClickModal: false,
          closeOnPressEscape: false,
        }
      )
        .then(() => {
          this.courseUnitSwitch();
          this.rtmDayLog("老师打开学生端目录", "老师手动打开学生端目录功能");
        })
        .catch(() => {});
    },
    // 视频预览
    previewVideo() {
      let routes = this.$router.resolve({
        name: "pvideos",
        query: {
          sourceVideoId: this.status,
        },
      });
      window.open(routes.href, "_blank");
    },
    // 课件预览
    previewCourse() {
      if (this.onlinePpt == "Y") {
        let routes = this.$router.resolve({
          path: "/ppt/looks",
          query: {
            content: this.sourceContent,
            ci: this.$route.query.ci,
            cn: this.$route.query.cn,
            ui: this.$route.query.ui,
            un: this.$route.query.un,
            ti: this.currentTaskListInfo.id,
            tsourceId: this.currentTaskListInfo.sourceId,
            tsort: this.currentTaskListInfo.sort,
          },
        });
        window.open(routes.href, "_blank");
      } else {
        let routes = this.$router.resolve({
          name: "pcourses",
          query: { sourceContent: this.sourceContent },
        });
        window.open(routes.href, "_blank");
      }
    },
    // 任务预览
    previewTasks() {
      let routes = this.$router.resolve({
        name: "ptaskss",
        query: {
          currenttaskID: this.currenttaskID,
          showTaskFlag: this.showTaskFlag,
          taskInfo: JSON.stringify(this.taskInfo),
        },
      });
      window.open(routes.href, "_blank");
    },
    async taskprepareGetInfo(taskID) {
      const res = await taskprepareGetInfo({
        taskId: this.currenttaskID,
      });
      if (res.body === null) {
        this.readyPlan = "";
      } else {
        this.readyPlan = res.body.content;
      }
    },
    typeshow(status) {
      if (this.courseStatus === "正在上课") {
        this.currentType = "success";
      } else if (this.courseStatus === "未上课") {
        this.currentType = "primary";
      } else if (this.courseStatus === "已上课") {
        this.currentType = "warning";
      }
    },
    spreadOutRight() {
      this.pickRightFlag = false;
      this.middleWidth = this.middleWidth - 5;
      this.$nextTick(() => {
        this.loadPlayerScript(this.loadPlayer);
      });
    },
    spreadOutLeft() {
      this.pickLeftFlag = false;
      this.middleWidth = this.middleWidth - 4;
    },
    pickupLeft() {
      this.pickLeftFlag = true;
      this.middleWidth = this.middleWidth + 4;
    },
    pickupRight() {
      this.pickRightFlag = true;
      this.middleWidth = this.middleWidth + 5;
    },

    async getLessonStudentInfoByIdWithPage() {
      const response = await getLessonStudentInfoByIdWithPage({
        lessonId: this.lessonId,
        pageNum: 1,
        pageSize: 10000,
        needCount: true,
      });
      this.lessonStudentList = response.body.list;
    },

    async attendLesson() {
      let that = this;
      if (this.courseStatus === "正在上课") {
        this.$confirm("确定现在要下课吗？", "温馨提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            that.rtmDayLog("老师下课", "老师点击了下课");
            let textObject = {
              sendType: "endCourse",
            };

            const response = await finishLesson({
              lessonId: this.lessonId,
            });
            if (response.state === "success") {
              that.globalChannel
                .sendMessage({ text: JSON.stringify(textObject) })
                .then(() => {
                  that.rtmDayLog("endCourse", "老师点击下课按钮");
                })
                .catch((error) => {
                  console.log("sendMesssage=======>fail");
                  that.rtmDayLog("endCourse", "老师点击下课按钮");
                  /* 频道消息发送失败的处理逻辑 */
                });
              this.onlineState = false;
              sessionStorage.setItem("courseStatus", "已上课");
              this.courseStatusText = "已上课";
              this.courseStatus = "已上课";
              this.typeshow("已上课");
            }
          })
          .catch(() => {});
      } else if (this.courseStatus === "未上课") {
        const response = await attendLesson({
          lessonId: this.lessonId,
        });
        if (response.state === "success") {
          this.rtmDayLog("老师上课", "老师点击了上课");
          this.getTmsToken();
        }
      } else if (this.courseStatus === "已上课") {
        window.$msg("不能重复上课", 2);
      }
    },

    pushCourseWare() {
      if (this.sourceContent === "") {
        window.$msg("此任务暂无课件", 2);
        return false;
      } else if (this.courseStatusText === "开始上课") {
        window.$msg("请先点击开始上课，再推送任务", 2);
        return false;
      } else if (this.courseStatusText === "已上课") {
        window.$msg("已经上课的不允许推送任务", 2);
        return false;
      }
      let routeUrl = this.$router.resolve({
        path: "/teacher/giveLesson-screen",
        query: {
          pushFlag: 1,
          lessonId: this.lessonId,
          courseUnitId: this.$route.query.courseUnitId,
          pushType: "courseWare",
          currentIndex: this.currentIndex - 1,
          content: this.sourceContent,
          ci: this.$route.query.ci,
          cn: this.$route.query.cn,
          ui: this.$route.query.ui,
          un: this.$route.query.un,
        },
      });
      window.open(routeUrl.href, "top");
    },
    pushTaskToscreen() {
      let routeUrl = this.$router.resolve({
        path: "/teacher/giveLesson-screen",
        query: {
          pushFlag: 1,
          lessonId: this.lessonId,
          courseUnitId: this.$route.query.courseUnitId,
          pushType: "task",
          currentIndex: this.currentIndex - 1,
          content: this.sourceContent,
          ci: this.$route.query.ci,
          cn: this.$route.query.cn,
          ui: this.$route.query.ui,
          un: this.$route.query.un,
        },
      });
      window.open(routeUrl.href, "top");
    },
    pushVideoToscreen() {
      if (this.status === "") {
        window.$msg("此任务暂时无课堂实录", 2);
        return false;
      } else if (this.courseStatusText === "开始上课") {
        window.$msg("请先点击开始上课，在推送任务", 2);
        return false;
      } else if (this.courseStatusText === "已上课") {
        window.$msg("已经上课的不允许推送任务", 2);
        return false;
      }
      let routeUrl = this.$router.resolve({
        path: "/teacher/giveLesson-screen",
        query: {
          pushFlag: 1,
          lessonId: this.lessonId,
          courseUnitId: this.$route.query.courseUnitId,
          pushType: "video",
          currentIndex: this.currentIndex - 1,
          content: this.sourceContent,
          ci: this.$route.query.ci,
          cn: this.$route.query.cn,
          ui: this.$route.query.ui,
          un: this.$route.query.un,
        },
      });
      window.open(routeUrl.href, "top");
    },
    pushTaskToStudentBefore(item) {
      this.currentProblemId = item.problem_id
      this.$nextTick((res) => {
        this.pushTaskToStudent()
      })
    },
    finishSendToStudnet() {
      this.sendNotasktoStudent();
    },
    sendToStudnet(data) {
      this.videoList.forEach((item) => {
        if (item.id === data) {
          this.taskInfo = item;
          this.currenttaskID = item.id;
        }
      });
      this.pushTaskToStudent();
    },
    pushTaskToStudent() {
      if (this.taskInfo.learnPurpose !== "Y") {
        window.$msg("此任务不支持推送给学员", 2);
        return false;
      }
      if (this.handfix || this.onlineState === false) {
        window.$msg("你已经从互动课堂掉线，请尝试刷新页面或者手动修复", 1);
        return false;
      }
      if (this.courseStatus == "正在上课") {
        this.sendTask();
      } else {
        if (this.courseStatusText === "开始上课") {
          window.$msg("请先点击开始上课，在推送任务", 2);
        } else if (this.courseStatusText === "已上课") {
          window.$msg("已经上课的不允许推送任务", 2);
        }
      }
    },
    sendNotasktoStudent() {
      let flag = this.lessonStudentList.find((item) => {
        return item.isLogin === "是";
      });

      if (flag === undefined) {
        window.$msg("暂时没有学员在线", 2);
        return false;
      }

      let textObject = {
        sendType: "gobackPlatform",
      };

      this.globalChannel
        .sendMessage({ text: JSON.stringify(textObject) })
        .then(() => {
          /* 频道消息发送成功的处理逻辑 */
          this.pushTaskId = "";
          window.$msg("结束任务成功");
          this.saveLastPush(JSON.stringify(textObject));
          this.rtmDayLog("allPush", "结束任务成功+taskId-----gobackPlatform");
        })
        .catch((error) => {
          console.log("sendMesssage=======>fail");
          this.rtmDayLog(
            "allPush",
            "结束任务失败+sendMesssage=======>fail" + error
          );
          /* 频道消息发送失败的处理逻辑 */
        });
    },
    sendTask() {
      let flag = this.lessonStudentList.find((item) => {
        return item.isLogin === "是";
      });

      if (flag === undefined) {
        window.$msg("暂时没有学员在线", 2);
        return false;
      }

      this.currentPushTaskId = this.currenttaskID;
      let textObject = {
        taskId: this.currenttaskID,
        questionId: this.currentProblemId,
        index: this.pythonorScratchIndex,
        sendType: "allPush",
      };

      this.globalChannel
        .sendMessage({ text: JSON.stringify(textObject) })
        .then(() => {
          /* 频道消息发送成功的处理逻辑 */
          this.pushTaskId = this.currenttaskID;
          window.$msg("推送任务成功");
          this.saveLastPush(JSON.stringify(textObject));
          this.rtmDayLog(
            "allPush",
            "推送任务成功+taskId-----" + this.currenttaskID
          );
        })
        .catch((error) => {
          console.log("sendMesssage=======>fail");
          this.rtmDayLog(
            "allPush",
            "推送任务失败+sendMesssage=======>fail" + error
          );
          /* 频道消息发送失败的处理逻辑 */
        });
    },
    async getTmsToken() {
      const response = await getAgoraToken({
        loginName: localStorage.getItem("userName"),
      });
      if (response.body.appid === null || response.body.rtmToken === null) {
        window.$msg("token值为空，不能登录直播间，请联系管理员", 2);
        return false;
      } else if (response.state === "success") {
        this.courseStatusText = "正在上课";
        this.courseStatus = "正在上课";
        this.onlineState = true;
        sessionStorage.setItem("courseStatus", "正在上课");
        this.typeshow("正在上课");
      }

      this.initAgora(response.body.rtmToken, response.body.appId);
    },

    initAgora(token, appid) {
      let that = this;
      this.globalClient = AgoraRTM.createInstance(appid);

      this.globalClient.on("ConnectionStateChanged", (newState, reason) => {
        if (reason === "INTERRUPTED") {
          //声网sdk和服务器断开连接
          this.realInterrupte = true;
          setTimeout(() => {
            if (this.realInterrupte) {
              this.onlineState = false;
              this.handfix = true;
              window.$msg("你已经从互动课堂离线，请检查你的网络连接", 1);
              this.rtmDayLog("INTERRUPTED", "老师掉线并且5秒之内没有连接上");
            }
          }, 5000);
        } else if (reason === "LOGIN_SUCCESS") {
          this.realInterrupte = false;
          this.onlineState = true;
          this.handfix = false;
          this.rtmDayLog("LOGIN_SUCCESS", "老师成功登录到rtm系统");
        } else if (reason === "REMOTE_LOGIN") {
          this.onlineState = false;
          this.handfix = true;
          this.rtmDayLog("REMOTE_LOGIN", "学生被踢退或者token过期");
        } else if (reason === "LOGIN_FAILURE") {
          this.onlineState = false;
          this.handfix = true;
          this.rtmDayLog("LOGIN_FAILURE", "SDK 登录 Agora RTM 系统失败。");
        }
        console.log(
          "on connection state changed to " + newState + " reason: " + reason
        );
      });
      this.globalClient
        .login({ token: token, uid: localStorage.getItem("userName") })
        .then(() => {
          // console.log('login success')
          this.rtmDayLog("RTM初始化成功", "RTM初始化成功");
          this.globalChannel = this.globalClient.createChannel(that.lessonId); // 此处传入频道 ID
          this.globalChannel
            .join()
            .then(() => {
              this.globalChannel.getMembers().then((members) => {
                /*获取此频道内的所有成员的uid*/
                // console.log(members)
                this.rtmDayLog("老师加入频道", "老师加入频道成功");
                members.forEach((item) => {
                  let student = that.lessonStudentList.find((value) => {
                    // console.log(value.loginName + '------------' + item)
                    return value.loginName === item;
                  });
                  if (student !== undefined) {
                    student.isLogin = "是";
                  }
                });
                that.$forceUpdate();
              });
            })
            .catch((error) => {
              /* 加入频道失败的处理逻辑 */
            });

          this.globalChannel.on("MemberJoined", (memberId) => {
            // text 为收到的频道消息文本，senderId 为发送方的 User ID
            /* 此频道内有新的成员加入回调 */
            // console.log(memberId + '====加入了频道')
            let student = that.lessonStudentList.find((value) => {
              return value.loginName === memberId;
            });
            student.isAttend = "是";
            student.isLogin = "是";
            that.$forceUpdate();
            if (this.currentPushTaskId) {
              let textObject = {
                taskId: this.currentPushTaskId,
                questionId: this.currentProblemId,
                sendType: "singlePush",
                loginName: memberId,
              };
              // this.globalClient
              //   .sendMessageToPeer(
              //     { text: JSON.stringify(textObject) },
              //     memberId
              //   )
              //   .then(() => {
              //     if (sendResult.hasPeerReceived) {
              //       this.rtmDayLog('singlePush', 'memberId-----' + memberId + '----taskId-----' + this.currentPushTaskId)
              //       // 你的代码：远端用户收到消息事件。
              //     } else {
              //       // 你的代码：服务器已收到消息，对端未收到消息。
              //     }
              //   })
              //   .catch((error) => {
              //     // 你的代码：点对点消息发送失败。
              //   })
            }
          });
          this.globalChannel.on("MemberLeft", (memberId) => {
            // text 为收到的频道消息文本，senderId 为发送方的 User ID
            /* 此频道内有成员退出回调 */
            // console.log(memberId + '====离开了频道')
            let student = that.lessonStudentList.find((value) => {
              return value.loginName === memberId;
            });
            student.isLogin = "否";
            that.$forceUpdate();
          });
        })
        .catch((err) => {
          this.rtmDayLog("RTM初始化失败", "RTM初始化失败login failure" + err);
          console.log("AgoraRTM client login failure", err);
        });
    },

    gotoPreviewRecord() {
      // console.log(JSON.stringify(this.taskInfo.type))
      if (this.taskInfo.type === "python" || this.taskInfo.type === "goc") {
        this.pythonRecordDialog = true;
      } else if (this.taskInfo.type === "测试") {
        this.recordDialog = true;
      } else if (this.taskInfo.type === "scratch") {
        this.scratchRecordDialog = true;
      } else if (this.taskInfo.type === "编程") {
        this.OjRecordDialog = true;
      }
    },
    async taskLeftList() {
      const res = await taskList({
        courseUnitId: this.$route.query.courseUnitId,
      });

      if (this.courseType !== "视频") {
        this.taskListArray = res.body;
        if (this.taskListArray.length !== 0) {
          this.currentTaskListInfo = res.body[0];
          this.currentIndex = 1;
          this.taskInfo = this.taskListArray[0];
          this.teacherPlan = this.taskListArray[0].teacherPlan;
          this.currenttaskID = this.taskListArray[0].id;
          this.listAttachmentVideo(this.taskListArray[0].id);
          this.listAttachmentCourseWare(this.taskListArray[0].id);
          this.taskprepareGetInfo(this.taskListArray[0].id);
        }
      } else {
        this.linkList = [];
        this.videoList = [];
        res.body.forEach((item) => {
          if (item.type !== "课件" && item.type !== "授课资料") {
            this.videoList.push(item);
          }
          if (item.type === "授课资料") {
            this.linkList.push(item);
          }
        });
        this.VideoValueList = [];
        this.linkList.forEach(async (item) => {
          const res = await listAttachment({
            taskId: item.id,
            type: "补课视频",
          });
          item.misslesonVideo = res.body;
          res.body.forEach((item) => {
            this.VideoValueList.push(item);
          });
        });

        if (this.linkList.length !== 0) {
          let thembInfo = this.linkList[0];
          setTimeout(() => {
            this.linkInfo = thembInfo.misslesonVideo[0];
            this.currentLinkId = this.linkInfo.id;
          }, 2000);
        }
        this.$forceUpdate();

        if (this.videoList.length !== 0) {
          this.currentTaskListInfo = res.body[0];
          this.currentIndex = 1;
          this.taskInfo = this.videoList[0];
          this.teacherPlan = this.videoList[0].teacherPlan;
          this.currenttaskID = this.videoList[0].id;
        }
      }
      switch (this.taskInfo.type) {
        case "pdf":
          this.showTaskFlag = 2;
          break;
        case "视频":
          this.showTaskFlag = 3;
          break;
        case "Pygame":
          this.showTaskFlag = 4;
          break;
        case "scratchjr":
          this.showTaskFlag = 5;
          break;
        default:
          this.showTaskFlag = 1;
      }
    },

    async listAttachmentVideo(taskID) {
      const res = await listAttachment({
        taskId: taskID,
        type: "补课视频",
      });
      // this.optionsVideo = res.body
      if (res.body.length !== 0) {
        this.status = res.body[0].content;
        const params = {
          sourceVideoId: res.body[0].content,
        };
        getVideoToken(params).then((res) => {
          if (res.state == "success") {
            this.vidInfo = JSON.parse(res.body.playToken);
            this.$nextTick(() => {
              if (this.vidInfo.data && this.vidInfo.data.token) {
                this.loadPlayerScript(this.loadPlayer);
              }
            });
          }
        });
      } else {
        this.status = "";
      }
    },
    async listAttachmentCourseWare(taskID) {
      const res = await listAttachment({
        taskId: taskID,
        type: "课件",
      });
      // if (res.body.length !== 0) {
      //   this.sourceContent =
      //     process.env.VUE_APP_PREVIEW_ADDRESS + res.body[0].content
      // } else {
      //   this.sourceContent = ''
      // }
      this.onlinePpt = "N";
      if (res.body.length !== 0) {
        if (res.body[0].onlinePpt == "Y") {
          this.onlinePpt = "Y";
          this.sourceContent = res.body[0].content;
          this.$nextTick(() => {
            if (
              this.$refs.pptIframeg &&
              this.$refs.pptIframeg.changeSlidesPage
            ) {
              this.$refs.pptIframeg.changeSlidesPage(taskID);
            }
          });
        } else if (
          res.body[0].onlinePpt == "N" &&
          res.body[0].content.indexOf("/") >= 0
        ) {
          this.sourceContent =
            process.env.VUE_APP_PREVIEW_ADDRESS + res.body[0].content;
        } else {
          this.sourceContent = "";
        }
      } else {
        this.sourceContent = "";
      }
    },
    async taskprepareEdit() {
      const res = await taskprepareEdit({
        taskId: this.currenttaskID,
        content: this.textarea,
      });
      if (res.state === "success") {
        window.$msg("提交备案成功");
      }
    },
    changeTaskByChild(taskID) {
      let item, index;
      this.taskListArray.forEach((i, j) => {
        if (i.id === taskID) {
          item = i;
          index = j;
        }
      });
      if (item) {
        this.changeTask(taskID, index, item);
      }
    },
    changeTaskClick(taskID, index, item) {
      this.linkVideoTaskId = taskID;
      this.linkVideoIndex = index;
      this.linkVideoItem = item;

      if (this.$refs.pptIframeg && this.$refs.pptIframeg.changeSlidesPage) {
        this.$refs.pptIframeg.changeSlidesPage(taskID);
      }

      if (item.type == "编程") {
        getQuestionList({ contest_id: item.sourceId }).then((res) => {
          if (res.state == "success") {
            this.questionList = res.body;
          }
        });
      } else {
        this.questionList = [];
      }
      this.taskInfo = item;
      this.currentIndex = index + 1;

      this.currenttaskID = taskID;
      if (this.courseType !== "视频") {
        this.teacherPlan = this.taskListArray[index].teacherPlan;
        this.listAttachmentVideo(taskID);
        this.listAttachmentCourseWare(taskID);
        this.taskprepareGetInfo(taskID);
      } else {
        this.showTaskPage = true;
        this.currentValue = index;
        switch (this.taskInfo.type) {
          case "视频":
            this.showTaskFlag = 3;
            break;
          default:
            this.showTaskFlag = 1;
        }
        document.body.click();
      }
      switch (item.type) {
        case "pdf":
          this.showTaskFlag = 2;
          break;
        case "视频":
          this.showTaskFlag = 3;
          break;
        case "Pygame":
          this.showTaskFlag = 4;
          break;
        case "scratchjr":
          this.showTaskFlag = 5;
          break;
        default:
          this.showTaskFlag = 1;
      }
    },
    changeTask(taskID, index, item) {
      this.showTaskFlag = 0;
      if (this.$refs.pptIframeg && this.$refs.pptIframeg.changeSlidesPage) {
        this.$refs.pptIframeg.changeSlidesPage(taskID);
      }
      if (item.type == "编程") {
        getQuestionList({ contest_id: item.sourceId }).then((res) => {
          if (res.state == "success") {
            this.questionList = res.body;
          }
        });
      } else {
        this.questionList = [];
      }
      this.taskInfo = item;
      this.currentIndex = index + 1;

      this.currenttaskID = taskID;
      if (this.courseType !== "视频") {
        this.teacherPlan = this.taskListArray[index].teacherPlan;
        this.listAttachmentVideo(taskID);
        this.listAttachmentCourseWare(taskID);
        this.taskprepareGetInfo(taskID);
      } else {
        this.showTaskPage = true;
        this.currentValue = index;
        switch (this.taskInfo.type) {
          case "视频":
            this.showTaskFlag = 3;
            break;
          default:
            this.showTaskFlag = 1;
        }
        document.body.click();
        if (item.learnPurpose === "Y") {
          if (this.courseStatus == "正在上课") {
            this.sendTask();
          } else {
            if (this.courseStatusText === "开始上课") {
              window.$msg("请先点击开始上课，在推送任务", 2);
            } else if (this.courseStatusText === "已上课") {
              return false;
            }
            return false;
          }
          if (this.handfix || this.onlineState === false) {
            window.$msg("你已经从互动课堂掉线，请尝试刷新页面或者手动修复", 1);
            return false;
          }
        } else {
          if (this.handfix || this.onlineState === false) {
            window.$msg("你已经从互动课堂掉线，请尝试刷新页面或者手动修复", 1);
            return false;
          }
          if (this.courseStatus == "正在上课") {
            this.sendNotasktoStudent();
          } else if (this.courseStatusText === "开始上课") {
            window.$msg("请先点击开始上课，在推送任务", 2);
            return false;
          }
        }
      }

      switch (item.type) {
        case "pdf":
          this.showTaskFlag = 2;
          break;
        case "视频":
          this.showTaskFlag = 3;
          break;
        case "Pygame":
          this.showTaskFlag = 4;
          break;
        case "scratchjr":
          this.showTaskFlag = 5;
          break;
        default:
          this.showTaskFlag = 1;
      }
    },
    loadPlayerScript(callback) {
      if (!window.polyvPlayer) {
        const myScript = document.createElement("script");
        myScript.setAttribute("src", this.vodPlayerJs);
        myScript.onload = callback;
        document.body.appendChild(myScript);
      } else {
        callback();
      }
    },

    loadPlayer() {
      this.destroyed();
      this.player = null;
      const polyvPlayer = window.polyvPlayer;
      this.player = polyvPlayer({
        wrap: this.videoEleId,
        // width: 800,
        height: this.autoHeight * 0.61412,
        vid: this.vidInfo.data.videoId,
        playsafe: this.vidInfo.data.token,
        code:
          "可达鸭编程—" +
          localStorage.getItem("userName") +
          "|" +
          localStorage.getItem("token_business"),
      });
    },
    destroyed() {
      if (this.player) {
        this.player.destroy();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/container.scss";

.tasktextClass {
}

.emptyVideo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-size: 14px;
  margin-bottom: 40px;
}

.studentitem {
  font-size: 14px;
  line-height: 30px;
}

.jiaoancontent {
  margin-left: 20px;
  font-size: 14px;
}

.jiaoantitle {
  font-size: 14px;
  font-weight: 600;
}

/deep/ .jiaoan {
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 100px;
}

/deep/ .parentScroll {
  overflow: scroll;
}

/deep/ .titleName {
  font-size: 16px;
  font-weight: 600;
}

.headertop {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/deep/ .taskitem {
  font-size: 14px;
  line-height: 40px;
}

/deep/ .line {
  background: #cecece;
  width: 100%;
  height: 1px;
  margin-top: 10px;
}

/deep/ .customBut {
  text-align: center;
  background: #f9f0ff;
  color: #722ed1;
  padding: 3px 10px;
  border-radius: 5px;
  font-size: 12px;
}

/deep/ .customButone {
  text-align: center;
  background: #fff2e8;
  color: #fa541c;
  margin-left: 5px;
  padding: 3px 10px;
  border-radius: 5px;
  font-size: 12px;
}

/deep/ .customButtwo {
  text-align: center;
  background: #f6ffed;
  color: #52c41a;
  margin-left: 5px;
  padding: 3px 10px;
  border-radius: 5px;
  font-size: 12px;
}

/deep/ .span-stategreen {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 6px;
  height: 6px;
  background-color: #4da54d;
  vertical-align: middle;
  border-radius: 50%;
  margin-right: 8px;
}

/deep/ .span-statered {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 6px;
  height: 6px;
  background-color: #ce433f;
  vertical-align: middle;
  border-radius: 50%;
  margin-right: 8px;
}

/deep/ .el-dialog__body {
  padding: 10px 0 0 0;
}

.kejianpanrent {
  display: flex;
  flex-direction: column;
  line-height: 30px;
}

.kejianitem {
  cursor: pointer;
}

.kejianactive {
  color: #f7cf47;
}

.rightList {
  position: absolute;
  right: 0px;
  height: 80%;
  top: 70px;
  right: 15px;
  z-index: 100000;
}

.rightzhan {
  position: fixed;
  right: 5px;
  z-index: 2001;
  top: 45%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: #ffffff;
  width: 30px;
  text-align: center;
  border: #a6a6a6 solid 1px;
  border-right: none;
  font-size: 14px;
}

.rightflod {
  position: fixed;
  right: 60px;
  z-index: 2001;
  top: 45%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: #ffffff;
  width: 30px;
  text-align: center;
  border: #a6a6a6 solid 1px;
  border-right: none;
  font-size: 14px;
}

.rightParent {
  position: fixed;
  right: 0;
  z-index: 2000;
  top: 0px;
  background-color: #ffffff;
  width: 60px;
  height: 100%;
  box-shadow: 5px 5px 10px black;
}

.rightContent {
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  color: #000000;
  font-size: 18px;
  margin-top: 120px;
  padding-right: 0px;
  cursor: pointer;
}

.floatClass {
  width: 85%;
  height: 85%;
  position: fixed;
  top: 8%;
  left: 3%;
  background: #ffffff;
  z-index: 1000;
}
</style>
