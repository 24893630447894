var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    [
      _c(
        "el-table",
        {
          staticClass: "tb-list",
          staticStyle: { "max-height": "600px", "overflow-y": "scroll" },
          attrs: {
            data: _vm.dataList,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { align: "center", label: "姓名" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(scope.row.name) +
                        "[" +
                        _vm._s(scope.row.loginName) +
                        "] "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "最高得分" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-row", [
                      scope.row.maxInDate !== null
                        ? _c("span", [_vm._v(_vm._s(scope.row.maxScore))])
                        : _c("span", [_vm._v("0")]),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "提交状态" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-row", [
                      scope.row.maxInDate !== null
                        ? _c(
                            "span",
                            {
                              style:
                                "color" +
                                ":" +
                                _vm.getStateColor(scope.row.maxInDate),
                            },
                            [_vm._v("已提交")]
                          )
                        : _c(
                            "span",
                            {
                              style:
                                "color" +
                                ":" +
                                _vm.getStateColor(scope.row.maxInDate),
                            },
                            [_vm._v("未提交")]
                          ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "最后提交时间" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-row", [
                      scope.row.maxInDate !== null
                        ? _c("span", [
                            _vm._v(
                              _vm._s(scope.row.maxInDate.substring(0, 19))
                            ),
                          ])
                        : _c("span", [_vm._v("无")]),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.pagination.currentPage,
          "page-sizes": _vm.pagination.pageSizes,
          "page-size": _vm.pagination.pageSize,
          layout: _vm.pagination.layout,
          total: _vm.pagination.total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }