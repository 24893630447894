<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    append-to-body
    @open="openDialog"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>批阅python提交</span>
    </div>
    <el-form ref="formData" :model="formData" :rules="rules" label-width="120px">
      <el-form-item label="提交结果：" prop="isCorrect" >
        <el-radio-group v-model="formData.isCorrect" @change="forceUpdate">
          <el-radio :label="true">正确</el-radio>
          <el-radio :label="false">错误</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <el-row slot="footer">
      <el-row style="text-align: right;margin-bottom: 15px;">
        <el-button type="primary" @click="confirm">确 定</el-button>
        <el-button @click="close" type="info">取 消</el-button>
      </el-row>
    </el-row>
  </el-dialog>
</template>

<script>
import { reviewPythonTaskCode } from '@/apiteacher/teacher'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    pythonData: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  data () {
    return {
      formData: {
        isCorrect: null
      },
      rules: {
        isCorrect: [
          { required: true, message: '请选择提交结果', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    forceUpdate () {
      this.$forceUpdate()
    },

    openDialog () {
      this.formData = {
        isCorrect: null
      }
      this.formData.isCorrect = this.pythonData.isCorrect
    },

    // 关闭
    close () {
      this.$emit('close')
    },

    // 批阅
    confirm () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          reviewPythonTaskCode({
            taskId: this.pythonData.taskId,
            userId: this.pythonData.userId,
            createTime: this.pythonData.createTime,
            isCorrect: this.formData.isCorrect
          }).then(res => {
            if (res.state === 'success') {
              window.$msg('批阅成功')
              this.close()
              this.$emit('updateView')
            }
          })
        }
      })
    }

  },
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/dialog.scss";
</style>
