<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="70%"
    @open="openDialog"
    top="5vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>刷题记录</span>
    </div>
    <el-tabs v-model="activeName" style="margin-left: 20px;">
      <el-tab-pane label="提交记录" name="first">
        <sub-record :contest_id="contestId" :lesson_id="lessonId"></sub-record>
      </el-tab-pane>
      <el-tab-pane label="提交统计" name="second">
        <sub-rank :contest_id="contestId" :lesson_id="lessonId"></sub-rank>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>

<script>
  import subRecord from '@/componentsTeacher/sub-record/'
  import subRank from '@/componentsTeacher/sub-rank/'
  export default {
    components:{subRecord,subRank },
    props: {
      show: {
        default: false,
        type: Boolean,
      },
      lessonId: {
        default: '',
        type: String,
      },
      contestId: {
        default: '',
        type: String,
      },
    },
    created() {
    },
    watch: {
      show() {
        if (this.show) {
          if (this.$refs.formData) {
            this.$refs.formData.resetFields()
          }
        }
      },
    },
    data() {
      return {
        activeName:'first',
      }
    },

    methods: {
      openDialog() {
        this.activeName = 'first'
      },
      close() {
        this.$emit('close')
      },
    },
  }
</script>

<style lang="scss" scoped>
@import '../../style/dialog.scss';
</style>
