var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "close-on-click-modal": false,
        width: "50%",
        "append-to-body": "",
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [_c("i"), _c("span", [_vm._v("批阅python提交")])]
      ),
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "提交结果：", prop: "isCorrect" } },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.forceUpdate },
                  model: {
                    value: _vm.formData.isCorrect,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "isCorrect", $$v)
                    },
                    expression: "formData.isCorrect",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: true } }, [_vm._v("正确")]),
                  _c("el-radio", { attrs: { label: false } }, [_vm._v("错误")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-row",
            { staticStyle: { "text-align": "right", "margin-bottom": "15px" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirm } },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                { attrs: { type: "info" }, on: { click: _vm.close } },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }