<template>
  <el-row>
    <el-row class="table">
      <el-row class="row-bg">
        <el-input
          placeholder="请输入内容"
          v-model.trim="inputVal"
          class="tb-search-input"
          @keyup.enter.native="search"
        >
          <el-select v-model="select" slot="prepend" placeholder="请选择" style="width: 120px;">
            <el-option label="问题标题" value="1"></el-option>
            <!-- <el-option label="问题id" value="2"></el-option> -->
          </el-select>
        </el-input>
        <el-input
          class="tb-sm-input"
          placeholder="请输入姓名"
          v-model="name"
          @keyup.enter.native="search"
        ></el-input>
        <el-input
          class="tb-sm-input"
          placeholder="请输入学号"
          v-model="loginName"
          @keyup.enter.native="search"
        ></el-input>
        <el-button @click="search" type="primary" size="medium" class="tb-button">搜索</el-button>
        <el-button @click="reset" type="primary" size="medium" class="tb-button">重置</el-button>
      </el-row>

      <el-table
        :data="dataList"
        border
        fit
        highlight-current-row
        class="tb-list" style="margin-top: 30px;"
      >
        <el-table-column label="提交人ID" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.loginName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="姓名" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="所属问题ID" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.problem_id }}</span>
          </template>
        </el-table-column>
        <el-table-column label="所属问题标题" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.problemName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="结果" align="center">
          <template slot-scope="scope">
            <span
              @click="subDetail(scope.row)"
              class="type-span"
              :style="'backgroundColor'+':'+ getStateColor(scope.row.result)"
            >
              {{getSubType(scope.row.result)}}
              <span
                v-if="scope.row.pass_rate && scope.row.pass_rate !==1"
              >{{(1-Number(scope.row.pass_rate)).toFixed(1)*100}}%</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="详情" align="center">
          <template slot-scope="scope">
            <el-row style="line-height:25px;">
              <i class="el-icon-time ico-time"></i>
              <span>{{scope.row.time}}  ms</span>
              <i class="el-icon-coin ico-bulk"></i>
              <span>{{scope.row.memory}}  KB</span>
              <br />
              <div style="margin-left: 8px;">
                <i class="el-icon-document"></i>
                <span style="margin-left: 5px;" >{{scope.row.code_length}}</span> B
                <span style="margin-left: 5px;">{{getLanText(scope.row.language)}}</span>
              </div>
            </el-row>
          </template>
        </el-table-column>
        <el-table-column label="提交时间" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.in_date | dateformat }}</span>
          </template>
        </el-table-column>
      </el-table>

      <el-row>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.currentPage"
          :page-sizes="pagination.pageSizes"
          :page-size="pagination.pageSize"
          :layout="pagination.layout"
          :total="pagination.total"
        ></el-pagination>
      </el-row>
    </el-row>
  </el-row>
</template>

<script>
import { getSumitLogByContest } from '@/apiteacher/teacher'

export default {
  props: {
    contest_id: {
      default: "",
      type: String
    },
    lesson_id: {
      default: "",
      type: String
    },
  },
  filters: {
    dateformat: function(val) {
      if (val == null) {
        return "";
      }
      val = val.substring(0, 19);
      return val;
    }
  },
  data() {
    return {
      inputVal: "",
      dataList: [],
      select: "1",
      name: "",
      loginName: "",
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0
      }
    };
  },
  created() {
    this.getSubmitHistory();
  },
  methods: {
    // 获取提交记录列表
    // 老师端-获取学生提交记录
    async getSubmitHistory() {
      const res = await getSumitLogByContest({
        needCount: true,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        contestId: this.contest_id,
        lessonId: this.lesson_id,
        title: this.select === '1' ? this.inputVal : '',
        name: this.name,
        loginName: this.loginName
      })
      this.dataList = res.body.list;
      this.pagination.total = res.body.total;
    },

    reset () {
      this.inputVal = ""
      this.select = "1"
      this.name = ""
      this.loginName = ""
    },

    getSubType(state) {
      switch (state) {
        case 0:
          return "等待";
          break;
        case 1:
          return "等待重判";
          break;
        case 2:
          return "编译中";
          break;
        case 3:
          return "运行并评判";
          break;
        case 4:
          return "正确";
          break;
        case 5:
          return "格式错误";
          break;
        case 6:
          return "答案错误";
          break;
        case 7:
          return "时间超限";
          break;
        case 8:
          return "内存超限";
          break;
        case 9:
          return "输出超限";
          break;
        case 10:
          return "运行错误";
          break;
        case 11:
          return "编译错误";
          break;
        case 12:
          return "编译成功";
          break;
        case 13:
          return "运行完成";
          break;
        default:
          return "未知状态";
      }
    },
    getStateColor(state) {
      switch (state) {
        case 4:
          return "#4DA54D";
          break;
        case 12:
          return "#4DA54D";
          break;
        case 13:
          return "#4DA54D";
          break;
        case 5:
          return "#CE433F";
          break;
        case 6:
          return "#CE433F";
          break;
        case 10:
          return "#CE433F";
          break;
        case 11:
          return "#CE433F";
          break;
        default:
          return "#EB9316";
      }
    },

    // 获取语言
    getLanText(state) {
      switch (state) {
        case 0: {
          return "C";
        }
        case 1: {
          return "C++";
        }
        case 2: {
          return "Pascal";
        }
        case 4: {
          return "Ruby";
        }
        case 5: {
          return "Bash";
        }
        case 6: {
          return "Python";
        }
        case 7: {
          return "php";
        }
        case 8: {
          return "perl";
        }
        case 9: {
          return "c#";
        }
        case 10: {
          return "objectiveC";
        }
        case 11: {
          return "freeBasic";
        }
        case 12: {
          return "scheme";
        }
        case 13: {
          return "Clang";
        }
        case 14: {
          return "Clang++";
        }
        case 15: {
          return "Lua";
        }
        case 16: {
          return "JavaScript";
        }
        case 17: {
          return "Go";
        }
      }
    },

    // 跳转详情页面
    subDetail(row) {
      let routeData = this.$router.resolve({
        path: '/teacher/question-subDetails',
        query: {
          resultData: JSON.stringify(row)
        }
      })
      window.open(routeData.href, '_blank')
    },

    gotoBack() {
      this.$router.back(-1);
    },

    // 搜索
    search() {
      this.pagination.currentPage = 1;
      this.getSubmitHistory();
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getSubmitHistory();
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.getSubmitHistory();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../style/table.scss";

.backtop {
  width: 100%;
  height: 50px;
  color: #ffffff;
  line-height: 50px;
  padding-left: 20px;
  font-size: 16px;
  background-color: rgb(90, 90, 90);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.td-input {
  width: 300px;
}
.ico-time {
  margin-right: 8px;
}
.ico-bulk {
  margin-left: 13px;
  margin-right: 8px;
}
</style>
