<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="60%"
    @open="openDialog"
    top="5vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-if="currenttype === 'goc'">goc提交记录</span>
      <span v-if="currenttype === 'python'">python提交记录</span>
    </div>
    <el-row class="table" style="padding-left: 15px;">
      <el-select v-model="searchtype" placeholder="请选择类型" @change="getSchoolValue" class="tb-smselect">
        <el-option v-for="item in searchTypeArray" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
      <el-input
        v-model.trim="userNameValue"
        :placeholder="placeholder"
        @keyup.enter.native="search"
        class="tb-sm-input"
      ></el-input>
      <el-button type="primary" size="medium" @click="searchData" class="tb-button">搜索</el-button>
      <el-button type="primary" size="medium" @click="reset" class="tb-button">重置</el-button>
      <el-table
        :data="dataList"
        border
        fit
        highlight-current-row
      >
        <template v-if="currenttype === 'goc'">
          <el-table-column label="姓名" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.creatorInfo.name}}</span>
            </template>
          </el-table-column>
          <el-table-column label="学号" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.creatorInfo.loginName}}</span>
            </template>
          </el-table-column>
          <el-table-column label="提交时间" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.createTime}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                style="margin-right: 10px;"
                size="small"
                type="primary"
                plain
                @click="lookGoCode(scope.row,scope.$index)"
              >查 看</el-button>
            </template>
          </el-table-column>
        </template>

        <template v-else>
          <el-table-column label="姓名" align="center" width="180">
            <template slot-scope="scope">
              <span v-if="currenttype === 'python'">{{scope.row.creatorInfo.name}}[{{scope.row.creatorInfo.loginName}}]</span>
              <span v-else>{{scope.row.userInfo.name}}[{{scope.row.userInfo.loginName}}]</span>
            </template>
          </el-table-column>
          <el-table-column label="批阅结果" align="center">
            <template slot-scope="scope">
              <span v-text="pythonText(scope.row.isCorrect)" :style="'color' + ':' + pythonColor(scope.row.isCorrect)"></span>
            </template>
          </el-table-column>
          <el-table-column label="提交时间" align="center">
            <template slot-scope="scope">
              <span v-if="currenttype === 'python'">{{scope.row.createTime | dateFormat }}</span>
              <span v-else>{{scope.row.createTime}}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button
                style="margin-right: 10px;"
                size="small"
                type="primary"
                plain
                @click="classManage(scope.row,scope.$index)"
              >查 看</el-button>
              <el-button
                style="margin-right: 10px;"
                size="small"
                type="primary"
                plain
                @click="readOver(scope.row,scope.$index)"
              >批 阅</el-button>
              <el-button
                v-if="currenttype === 'pythonactivity'"
                style="margin-right: 10px;"
                size="small"
                type="primary"
                plain
                @click="downloadManage(scope.row)"
              >下载作品</el-button>
            </template>
          </el-table-column>
        </template>
      </el-table>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :total="pagination.total"
      ></el-pagination>

      <!-- python 批阅 -->
      <python-read :show="pythonDialog" @close="pythonDialog=false,pythonData=null" :pythonData="pythonData" @updateView="searchData"></python-read>
    </el-row>
  </el-dialog>
</template>

<script>
  import { getSubmitList ,getActivitySubmitList, getGocList} from '@/apiteacher/teacher'
  import axios from "axios";
  import PythonRead from '@/componentsTeacher/python-read'
  import pythonText from '@/mixins/getStateText'
  import pythonColor from '@/mixins/getStateColor'
  export default {
    components: { PythonRead },
    mixins: [pythonText,pythonColor],
    props: {
      show: {
        default: false,
        type: Boolean,
      },
      currenttype: {
        default:'',
        type:String
      },
      taskId: {
        default:'',
        type:String
      },
      lessonId: {
        default:'',
        type:String
      },
    },
    data () {
      return {
        searchTypeArray:[
          {
            value:1,
            label:'姓名'
          },
          {
            value:2,
            label:'学号'
          },
        ],
        userNameValue: '',
        searchtype: '姓名',
        placeholder: '请输入姓名',
        dataList: [],
        pagination: {
          currentPage: 1,
          pageSize: 10,
          pageSizes: [10, 20, 50, 100],
          layout: 'total, sizes, prev, pager, next, jumper',
          total: 0
        },
        pythonDialog: false,
        pythonData: null,
        activityId: 0,
      }
    },

    methods: {
      // 关闭
      close () {
        this.$emit('close')
      },
      openDialog() {
        if(this.currenttype === 'python') {
          this.getSubmitList()
        } else if (this.currenttype === 'goc') {
          this.getSubmitGoCList()
        }
        var lett = this;
        document.onkeydown = function(e) {
          var key = window.event.keyCode;
          if (key == 13) {
            lett.searchData();
          }
        }
      },
      async getSubmitGoCList () {
        this.dataList = []
        const res = await getGocList({
          pageNum: this.pagination.currentPage,
          pageSize: this.pagination.pageSize,
          needCount: true,
          userName:(this.searchtype === '姓名' || this.searchtype === 1) ? this.userNameValue:'',
          userLoginName:this.searchtype ===  2 ? this.userNameValue:'',
          taskId: this.taskId,
          lessonId: this.lessonId,
        })
        this.dataList = res.body.list
        this.pagination.total = res.body.total
      },

      reset(){
        this.userNameValue = ''
        this.searchtype = '姓名'
        this.placeholder = '请输入姓名'
      },
      // 选择校区
      getSchoolValue (val) {
        if(val === 1) {
          this.placeholder = '请输入姓名'
        }else if(val === 2) {
          this.placeholder = '请输入学号'
        }
      },
      searchData() {
        this.pagination.currentPage = 1
        if(this.currenttype === 'python') {
          this.getSubmitList()
        } else if (this.currenttype === 'goc') {
          this.getSubmitGoCList()
        }
      },
      // 获取任务python列表
      async getSubmitList () {
        this.dataList = []

        const res = await getSubmitList({
          pageNum: this.pagination.currentPage,
          pageSize: this.pagination.pageSize,
          needCount: true,
          userName:(this.searchtype === '姓名' || this.searchtype === 1) ? this.userNameValue:'',
          userLoginName:this.searchtype ===  2 ? this.userNameValue:'',
          lessonId: this.lessonId,
          taskId: this.taskId,
        })
        this.dataList = res.body.list
        this.pagination.total = res.body.total
      },

      // 获取活动python列表
      async getActivitySubmitList () {
        this.dataList = []

        const res = await getActivitySubmitList({
          pageNum: this.pagination.currentPage,
          pageSize: this.pagination.pageSize,
          needCount: true,
          userSearch:this.userNameValue,
          activityId: this.activityId,
        })
        this.dataList = res.body.list
        this.pagination.total = res.body.total
      },
      downloadManage(row) {
        var url = process.env.VUE_APP_API_ADDRESS+'/activity/downloadFile';
        axios({
            method:'post',
            url:url,
            headers:{'Accept-Encoding': '*'},
            responseType: 'blob',
            data:{
              activityId: row.activityId,
              userId: row.userId
            }
          },
        ).then((response=>{
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', row.userInfo.name+'作品.zip');
            document.body.appendChild(link);
            link.click();
          })
        )

      },
      // 查看python代码
      classManage (row,index) {
        // console.log('code', row.code)
        // let a = encodeURIComponent(decodeURIComponent(row.code).replace(/↵/g, '<br/>'))
        // console.log('code', a)

        var titlethemb
        if(row.taskInfo = null || row.taskInfo == undefined) {
          titlethemb = 'python活动'
        } else {
          titlethemb = row.taskInfo.title
        }
        let routeData = this.$router.resolve({
          path: '/teacher/preview-pythonfile',
          query: {
            code: this.dataList[index].code
          }
        })
        window.open(routeData.href, '_blank')
      },

      // 批阅-dialog打开
      readOver (row, index) {
        this.pythonData = row
        this.pythonDialog = true
      },

      // 分页
      handleCurrentChange (val) {
        this.pagination.currentPage = val
        if(this.currenttype === 'python') {
          this.getSubmitList()
        } else if (this.currenttype === 'goc') {
          this.getSubmitGoCList()
        }
      },
      handleSizeChange (val) {
        this.pagination.pageSize = val
        if(this.currenttype === 'python') {
          this.getSubmitList()
        } else if (this.currenttype === 'goc') {
          this.getSubmitGoCList()
        }
      },

      lookGoCode (row, index) {
        // console.log('lookGoCode', row, index)
        let routeData = this.$router.resolve({
          path: '/research/preview-task',
          query: {
            taskID: row.taskId,
            code: row.code
          }
        })
        window.open(routeData.href, '_blank')
      }
    },
  }
</script>

<style lang="scss" scoped>
@import '../../style/dialog.scss';
@import "../../style/table.scss";
</style>
