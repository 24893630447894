var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "input_video",
      staticStyle: {
        width: "96%",
        "margin-left": "2%",
        "margin-top": "1.5%",
        "min-height": "560px",
        "padding-top": "calc(10px)",
        "background-color": "#fff",
      },
    },
    [
      _vm.sourceVideoId
        ? _c("div", [
            _c("div", {
              staticStyle: { margin: "0 auto" },
              attrs: { id: "playerer" },
            }),
          ])
        : _c(
            "div",
            {
              staticStyle: {
                "text-align": "center",
                "margin-top": "100px",
                "font-size": "16px",
              },
            },
            [_vm._v(" 暂无视频 ")]
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }