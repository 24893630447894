var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "parentScroll",
          style: { height: _vm.autoHeight + "px" },
        },
        [
          _vm.updownFlag
            ? _c("el-card", { attrs: { shadow: "nover" } }, [
                _c("div", { staticClass: "headertop" }, [
                  _c("span", { staticClass: "titleName" }, [
                    _vm._v(" " + _vm._s(_vm.courseTitle) + " "),
                  ]),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        float: "right",
                        margin: "15px",
                        display: "flex",
                        "align-items": "center",
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                            "margin-right": "100px",
                          },
                        },
                        [
                          _vm._v(" 互动课堂： "),
                          _vm.onlineState
                            ? _c("div", [
                                _c("span", { staticClass: "span-stategreen" }),
                                _c(
                                  "span",
                                  { staticStyle: { color: "#4da54d" } },
                                  [_vm._v("在线")]
                                ),
                              ])
                            : _vm._e(),
                          !_vm.onlineState
                            ? _c(
                                "div",
                                [
                                  _c("span", { staticClass: "span-statered" }),
                                  _c(
                                    "span",
                                    { staticStyle: { color: "#ce433f" } },
                                    [_vm._v("离线")]
                                  ),
                                  _vm.handfix && _vm.courseStatus === "正在上课"
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "el-icon-s-tools",
                                          staticStyle: {
                                            "font-size": "14px",
                                            "margin-left": "15px",
                                          },
                                          attrs: {
                                            size: "small",
                                            type: "primary",
                                          },
                                          on: { click: _vm.againInitAgora },
                                        },
                                        [_vm._v("手动修复 ")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-right": "40px" },
                          attrs: { size: "mini", type: "info" },
                          on: {
                            click: function ($event) {
                              _vm.drawer = true
                            },
                          },
                        },
                        [_vm._v("学员状态 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-right": "50px" },
                          attrs: { size: "mini", type: _vm.currentType },
                          on: { click: _vm.attendLesson },
                        },
                        [_vm._v(" " + _vm._s(_vm.courseStatusText) + " ")]
                      ),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.courseType === "视频"
            ? _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    display: "flex",
                    "justify-content": "center",
                  },
                },
                [
                  _c("i", {
                    class: !_vm.updownFlag
                      ? "el-icon-arrow-down"
                      : "el-icon-arrow-up",
                    staticStyle: { "font-size": "20px", cursor: "pointer" },
                    on: { click: _vm.upDownClick },
                  }),
                ]
              )
            : _vm._e(),
          _vm.courseType !== "视频"
            ? _c(
                "el-row",
                { staticStyle: { "margin-top": "20px" } },
                [
                  !_vm.pickLeftFlag
                    ? _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-card",
                            {
                              staticStyle: { "overflow-y": "scroll" },
                              style: {
                                height: _vm.autoHeight * 0.87001 + "px",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "titleName",
                                  staticStyle: {
                                    "font-size": "15px",
                                    "margin-top": "10px",
                                    "margin-bottom": "10px",
                                  },
                                },
                                [
                                  _vm._v(" 任务列表 "),
                                  _c("i", {
                                    staticClass: "el-icon-s-fold",
                                    staticStyle: {
                                      float: "right",
                                      "font-size": "18px",
                                    },
                                    on: { click: _vm.pickupLeft },
                                  }),
                                ]
                              ),
                              _vm._l(_vm.taskListArray, function (item, index) {
                                return _c("div", { key: index }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "taskitem",
                                      staticStyle: { cursor: "pointer" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeTask(
                                            item.id,
                                            index,
                                            item
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " 任务" +
                                            _vm._s(index + 1) +
                                            " " +
                                            _vm._s(item.title) +
                                            " "
                                        ),
                                      ]),
                                      _c("el-row", [
                                        _c(
                                          "span",
                                          { staticClass: "customBut" },
                                          [_vm._v(_vm._s(item.type))]
                                        ),
                                        item.practicePurpose === "Y"
                                          ? _c(
                                              "span",
                                              { staticClass: "customButone" },
                                              [_vm._v("课堂练习")]
                                            )
                                          : _vm._e(),
                                        item.learnPurpose === "Y"
                                          ? _c(
                                              "span",
                                              { staticClass: "customButtwo" },
                                              [_vm._v("学员可用")]
                                            )
                                          : _vm._e(),
                                      ]),
                                      _c("div", { staticClass: "line" }),
                                    ],
                                    1
                                  ),
                                ])
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _c(
                        "el-col",
                        {
                          staticStyle: { "margin-top": "20px" },
                          attrs: { span: 0.5 },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-s-unfold",
                            staticStyle: { "font-size": "18px" },
                            on: { click: _vm.spreadOutLeft },
                          }),
                        ]
                      ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-left": "20px" },
                      attrs: { span: _vm.middleWidth },
                    },
                    [
                      _c(
                        "el-card",
                        { staticStyle: { "line-height": "50px" } },
                        [
                          _c("span", [
                            _vm._v("任务" + _vm._s(_vm.currentIndex)),
                          ]),
                          _c(
                            "span",
                            { staticStyle: { "margin-left": "15px" } },
                            [_vm._v(_vm._s(_vm.taskInfo.title))]
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "customBut",
                              staticStyle: { "margin-left": "15px" },
                            },
                            [_vm._v(" " + _vm._s(_vm.taskInfo.type))]
                          ),
                          _vm.taskInfo.practicePurpose === "Y"
                            ? _c("span", { staticClass: "customButone" }, [
                                _vm._v("课堂练习"),
                              ])
                            : _vm._e(),
                          _vm.taskInfo.learnPurpose === "Y"
                            ? _c("span", { staticClass: "customButtwo" }, [
                                _vm._v("学员可用"),
                              ])
                            : _vm._e(),
                          _vm.sourceContent !== ""
                            ? _c("span", { staticStyle: { float: "right" } }, [
                                _c("i", {
                                  staticClass: "el-icon-full-screen",
                                  attrs: { size: "24" },
                                  on: { click: _vm.previewCourse },
                                }),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "el-card",
                        {
                          staticStyle: {
                            "line-height": "50px",
                            "margin-top": "10px",
                          },
                        },
                        [
                          _c(
                            "el-tabs",
                            {
                              model: {
                                value: _vm.activeName,
                                callback: function ($$v) {
                                  _vm.activeName = $$v
                                },
                                expression: "activeName",
                              },
                            },
                            [
                              _c(
                                "el-tab-pane",
                                { attrs: { label: "课件", name: "first" } },
                                [
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                          },
                                          on: { click: _vm.pushCourseWare },
                                        },
                                        [_vm._v(" 推送到大屏 ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.sourceContent !== "" &&
                                  _vm.sourceContent.indexOf("/") > -1
                                    ? _c("div", [
                                        _c(
                                          "div",
                                          {
                                            style: {
                                              height:
                                                _vm.autoHeight * 0.61412 + "px",
                                            },
                                            attrs: { id: "waterMaskId" },
                                          },
                                          [
                                            _c("iframe", {
                                              staticStyle: {
                                                width: "100%",
                                                height: "100%",
                                              },
                                              attrs: {
                                                src: _vm.sourceContent,
                                                allowfullscreen: "true",
                                              },
                                            }),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.sourceContent !== "" &&
                                            _vm.sourceContent.indexOf("/") ==
                                              -1,
                                          expression:
                                            "\n                  sourceContent !== '' && sourceContent.indexOf('/') == -1\n                ",
                                        },
                                      ],
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%",
                                        overflow: "auto",
                                      },
                                    },
                                    [
                                      _c("LookPpt", {
                                        ref: "pptIframeg",
                                        attrs: { content: _vm.sourceContent },
                                        on: {
                                          changeTaskByChild:
                                            _vm.changeTaskByChild,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.sourceContent,
                                          expression: "!sourceContent",
                                        },
                                      ],
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "justify-content": "center",
                                            "align-items": "center",
                                            "font-size": "14px",
                                          },
                                          style: {
                                            height: _vm.autoHeight * 0.1 + "px",
                                          },
                                        },
                                        [_vm._v(" 暂无课件 ")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm.taskInfo.type != "课件"
                                ? _c(
                                    "el-tab-pane",
                                    {
                                      attrs: {
                                        label: "任务详情",
                                        name: "second",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            padding: "20px",
                                            "padding-left": "0px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                size: "mini",
                                                type: "primary",
                                              },
                                              on: {
                                                click: _vm.pushTaskToscreen,
                                              },
                                            },
                                            [_vm._v("推送到大屏 ")]
                                          ),
                                          _vm.questionList.length == 0
                                            ? _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    size: "mini",
                                                    type: "primary",
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.pushTaskToStudent,
                                                  },
                                                },
                                                [_vm._v("推送到学员 ")]
                                              )
                                            : _c(
                                                "el-dropdown",
                                                {
                                                  staticStyle: {
                                                    margin: "0 10px",
                                                  },
                                                  on: {
                                                    command:
                                                      _vm.pushTaskToStudentBefore,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        size: "mini",
                                                        type: "primary",
                                                      },
                                                    },
                                                    [_vm._v("推送到学员")]
                                                  ),
                                                  _c(
                                                    "el-dropdown-menu",
                                                    {
                                                      attrs: {
                                                        slot: "dropdown",
                                                      },
                                                      slot: "dropdown",
                                                    },
                                                    _vm._l(
                                                      _vm.questionList,
                                                      function (i, j) {
                                                        return _c(
                                                          "el-dropdown-item",
                                                          {
                                                            key: j,
                                                            attrs: {
                                                              command: i,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  i.problemName
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                          _c(
                                            "el-button",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.taskInfo.type ==
                                                      "scratch" ||
                                                    _vm.taskInfo.type ==
                                                      "goc" ||
                                                    _vm.taskInfo.type ==
                                                      "python" ||
                                                    _vm.taskInfo.type ==
                                                      "测试" ||
                                                    _vm.taskInfo.type == "编程",
                                                  expression:
                                                    "\n                    taskInfo.type == 'scratch' ||\n                    taskInfo.type == 'goc' ||\n                    taskInfo.type == 'python' ||\n                    taskInfo.type == '测试' ||\n                    taskInfo.type == '编程'\n                  ",
                                                },
                                              ],
                                              attrs: {
                                                size: "mini",
                                                type: "info",
                                              },
                                              on: {
                                                click: _vm.gotoPreviewRecord,
                                              },
                                            },
                                            [_vm._v("查看提交记录 ")]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                height: "calc(100%)",
                                              },
                                            },
                                            [
                                              _vm.showTaskFlag === 1 &&
                                              _vm.currenttaskID &&
                                              _vm.taskInfo.type !== "Pygame" &&
                                              _vm.taskInfo.type !== "scratchjr"
                                                ? _c("previewTaskShow", {
                                                    style: {
                                                      height:
                                                        _vm.autoHeight * 0.66 +
                                                        "px",
                                                    },
                                                    attrs: {
                                                      taskID: _vm.currenttaskID,
                                                    },
                                                    on: {
                                                      synchroIndex:
                                                        _vm.synchroIndex,
                                                      asyncproblemId:
                                                        _vm.asyncproblemId,
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm.showTaskFlag === 2 &&
                                              _vm.currenttaskID
                                                ? _c("pdfpreviewShow", {
                                                    staticStyle: {
                                                      width: "100%",
                                                      "overflow-y": "scroll",
                                                    },
                                                    style: {
                                                      height:
                                                        _vm.autoHeight * 0.66 +
                                                        "px",
                                                    },
                                                    attrs: {
                                                      taskID: _vm.currenttaskID,
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm.showTaskFlag === 3
                                                ? _c("previewVideoShow", {
                                                    attrs: {
                                                      sourceId:
                                                        _vm.taskInfo.sourceId,
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm.taskInfo.type === "Pygame"
                                                ? _c("previewPygameShow", {
                                                    style: {
                                                      height:
                                                        _vm.autoHeight * 0.66 +
                                                        "px",
                                                      overflowY: "auto",
                                                    },
                                                    attrs: {
                                                      sourceId:
                                                        _vm.taskInfo.sourceId,
                                                      taskInfo: _vm.taskInfo,
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm.taskInfo.type === "scratchjr"
                                                ? _c("previewScratchJrShow", {
                                                    style: {
                                                      height:
                                                        _vm.autoHeight * 0.66 +
                                                        "px",
                                                      overflowY: "auto",
                                                    },
                                                    attrs: {
                                                      sourceId:
                                                        _vm.taskInfo.sourceId,
                                                      taskInfo: _vm.taskInfo,
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "el-tab-pane",
                                { attrs: { label: "说课视频", name: "third" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "titleName",
                                      staticStyle: {
                                        "font-size": "14px",
                                        padding: "15px 0",
                                      },
                                    },
                                    [
                                      _vm.status !== ""
                                        ? _c("i", {
                                            staticClass: "el-icon-full-screen",
                                            staticStyle: {
                                              "font-size": "18px",
                                              "margin-right": "10px",
                                            },
                                            on: { click: _vm.previewVideo },
                                          })
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm.status !== ""
                                    ? _c("div", [
                                        _c("div", {
                                          staticStyle: { margin: "0 auto" },
                                          attrs: { id: "player1" },
                                        }),
                                      ])
                                    : _c("div", { staticClass: "emptyVideo" }, [
                                        _vm._v("暂无视频"),
                                      ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  !_vm.pickRightFlag
                    ? _c(
                        "el-col",
                        {
                          staticStyle: { "margin-left": "20px" },
                          attrs: { span: _vm.rightauto },
                        },
                        [
                          _c(
                            "el-card",
                            {
                              staticStyle: {
                                "line-height": "30px",
                                padding: "10px 0px",
                                "overflow-y": "auto",
                              },
                              style: {
                                height: _vm.autoHeight * 0.87001 + "px",
                              },
                            },
                            [
                              _c("el-row", [
                                _c(
                                  "span",
                                  {
                                    staticClass: "titleName",
                                    staticStyle: { "font-size": "14px" },
                                  },
                                  [_vm._v("教案")]
                                ),
                                _c("i", {
                                  staticClass: "el-icon-s-unfold",
                                  staticStyle: {
                                    float: "right",
                                    "font-size": "18px",
                                    "margin-left": "15px",
                                  },
                                  on: { click: _vm.pickupRight },
                                }),
                              ]),
                              _c("div", [
                                _c("div", { staticClass: "jiaoantitle" }, [
                                  _vm._v("【课堂教案】"),
                                ]),
                                _c("div", {
                                  staticClass: "jiaoancontent",
                                  attrs: { id: "result" },
                                }),
                                _c("div", { staticClass: "jiaoantitle" }, [
                                  _vm._v("【备课教案】"),
                                ]),
                                _vm.readyPlan
                                  ? _c(
                                      "div",
                                      { staticClass: "jiaoancontent" },
                                      [
                                        _vm._v(
                                          " " + _vm._s(_vm.readyPlan) + " "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "jiaoancontent" },
                                      [_vm._v("暂无备课教案")]
                                    ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "el-col",
                        {
                          staticStyle: { "margin-top": "20px" },
                          attrs: { span: 0.5 },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-s-fold",
                            staticStyle: { "font-size": "18px" },
                            on: { click: _vm.spreadOutRight },
                          }),
                        ]
                      ),
                ],
                1
              )
            : _vm.courseType === "视频" && _vm.courseStatus === "正在上课"
            ? _c(
                "el-row",
                [
                  _c(
                    "div",
                    [
                      _c("preview-video-modle", {
                        ref: "previewvideomodle",
                        attrs: {
                          sourceId: _vm.linkInfo.content,
                          type: _vm.linkInfo.type,
                          linkId: _vm.linkInfo.id,
                        },
                        on: {
                          sendToStudnet: _vm.sendToStudnet,
                          finishSendToStudnet: _vm.finishSendToStudnet,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.flodFlag
                    ? _c(
                        "div",
                        {
                          staticClass: "rightflod",
                          on: {
                            click: function ($event) {
                              _vm.flodFlag = false
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-arrow-right",
                            staticStyle: { "font-size": "12px" },
                          }),
                          _c("div", [_vm._v("收")]),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "3px",
                                "margin-bottom": "3px",
                              },
                            },
                            [_vm._v("起")]
                          ),
                        ]
                      )
                    : _vm._e(),
                  !_vm.flodFlag
                    ? _c(
                        "div",
                        {
                          staticClass: "rightzhan",
                          on: {
                            click: function ($event) {
                              _vm.flodFlag = true
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "el-icon-arrow-left",
                            staticStyle: { "font-size": "12px" },
                          }),
                          _c("div", [_vm._v("展")]),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "3px",
                                "margin-bottom": "3px",
                              },
                            },
                            [_vm._v("开")]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.flodFlag
                    ? _c(
                        "div",
                        { staticClass: "rightParent" },
                        [
                          _c(
                            "el-menu",
                            { staticClass: "rightContent" },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "上一个",
                                    placement: "left",
                                  },
                                },
                                [
                                  _c(
                                    "el-menu-item",
                                    {
                                      attrs: { index: "1" },
                                      on: { click: _vm.preCourseTask },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-top",
                                        staticStyle: { "font-size": "18px" },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-menu-item",
                                { attrs: { index: "2" } },
                                [
                                  _c(
                                    "el-popover",
                                    {
                                      staticStyle: { "margin-top": "15px" },
                                      attrs: {
                                        placement: "left",
                                        width: "380",
                                        trigger: "hover",
                                      },
                                    },
                                    [
                                      _c("div", [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-weight": "bold",
                                              "font-size": "14px",
                                            },
                                          },
                                          [_vm._v("环节列表")]
                                        ),
                                        _c(
                                          "ul",
                                          {
                                            staticStyle: {
                                              "font-size": "14px",
                                              "line-height": "35px",
                                              color: "#222222",
                                            },
                                          },
                                          _vm._l(
                                            _vm.linkList,
                                            function (item, index) {
                                              return _c(
                                                "div",
                                                {
                                                  key: index,
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-data-board",
                                                    staticStyle: {
                                                      "font-size": "18px",
                                                      "margin-right": "15px",
                                                    },
                                                  }),
                                                  _c("span", [
                                                    _vm._v(_vm._s(item.title)),
                                                  ]),
                                                  _vm._l(
                                                    item.misslesonVideo,
                                                    function (value, index) {
                                                      return item.misslesonVideo
                                                        ? _c(
                                                            "li",
                                                            {
                                                              key: index,
                                                              staticStyle: {
                                                                "margin-left":
                                                                  "20px",
                                                                "font-size":
                                                                  "14px",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  class:
                                                                    _vm.currentLinkId ===
                                                                    value.id
                                                                      ? "kejianactive"
                                                                      : "",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.changeLinkVideo(
                                                                          value,
                                                                          index
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        value.title
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    }
                                                  ),
                                                ],
                                                2
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]),
                                      _c("img", {
                                        staticStyle: {
                                          width: "21px",
                                          height: "21px",
                                          "margin-left": "2px",
                                        },
                                        attrs: {
                                          slot: "reference",
                                          src: require("../../assets/images/list.png"),
                                        },
                                        slot: "reference",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "下一个",
                                    placement: "left",
                                  },
                                },
                                [
                                  _c(
                                    "el-menu-item",
                                    {
                                      attrs: { index: "3" },
                                      on: { click: _vm.nextCourseTask },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-bottom",
                                        staticStyle: { "font-size": "18px" },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-menu-item",
                                { attrs: { index: "4" } },
                                [
                                  _c(
                                    "el-popover",
                                    {
                                      staticStyle: { "margin-top": "15px" },
                                      attrs: {
                                        placement: "left",
                                        width: "380",
                                        trigger: "hover",
                                      },
                                    },
                                    [
                                      _c("div", [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-weight": "bold",
                                              "font-size": "14px",
                                            },
                                          },
                                          [_vm._v("任务列表")]
                                        ),
                                        _c(
                                          "ul",
                                          {
                                            staticStyle: {
                                              "font-size": "14px",
                                              "line-height": "35px",
                                              color: "#222222",
                                            },
                                          },
                                          _vm._l(
                                            _vm.videoList,
                                            function (item, index) {
                                              return _c(
                                                "li",
                                                {
                                                  key: index,
                                                  class:
                                                    _vm.currentValue === index
                                                      ? "kejianactive"
                                                      : "",
                                                  staticStyle: {
                                                    cursor: "pointer",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.changeTaskClick(
                                                        item.id,
                                                        index,
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "el-icon-document",
                                                    staticStyle: {
                                                      "font-size": "14px",
                                                      "margin-right": "15px",
                                                    },
                                                  }),
                                                  _vm._v(
                                                    _vm._s(item.title) + " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]),
                                      _c("i", {
                                        staticClass: "el-icon-document",
                                        staticStyle: { "font-size": "18px" },
                                        attrs: { slot: "reference" },
                                        slot: "reference",
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-dialog",
                    {
                      attrs: {
                        visible:
                          _vm.currenttaskID &&
                          _vm.videoList.length !== 0 &&
                          _vm.showTaskPage,
                        top: "3vh",
                        width: "80%",
                        "before-close": _vm.close,
                      },
                      on: {
                        "update:visible": function ($event) {
                          return _vm.$set(
                            _vm.currenttaskID && _vm.videoList,
                            "length !== 0 && showTaskPage",
                            $event
                          )
                        },
                      },
                    },
                    [
                      _vm.pushTaskId === _vm.currenttaskID
                        ? _c(
                            "el-button",
                            {
                              staticStyle: { "margin-left": "20px" },
                              attrs: { type: "danger", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.sendNotasktoStudent()
                                },
                              },
                            },
                            [_vm._v("结束推送 ")]
                          )
                        : _c(
                            "el-button",
                            {
                              staticStyle: { "margin-left": "20px" },
                              attrs: { type: "primary", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.changeTask(
                                    _vm.linkVideoTaskId,
                                    _vm.linkVideoIndex,
                                    _vm.linkVideoItem
                                  )
                                },
                              },
                            },
                            [_vm._v("推送给学员 ")]
                          ),
                      _vm.showTaskFlag === 1 && _vm.currenttaskID
                        ? _c("previewTaskShow", {
                            style: { height: _vm.autoHeight - 60 + "px" },
                            attrs: { taskID: _vm.currenttaskID },
                            on: {
                              synchroIndex: _vm.synchroIndex,
                              asyncproblemId: _vm.asyncproblemId,
                            },
                          })
                        : _vm._e(),
                      _vm.showTaskFlag === 2 && _vm.currenttaskID
                        ? _c("pdfpreviewShow", {
                            staticStyle: {
                              width: "100%",
                              "overflow-y": "scroll",
                            },
                            attrs: { taskID: _vm.currenttaskID },
                          })
                        : _vm._e(),
                      _vm.showTaskFlag === 3
                        ? _c("preview-video-task", {
                            ref: "previewVideo",
                            attrs: { sourceId: _vm.taskInfo.sourceId },
                          })
                        : _vm._e(),
                      _vm.showTaskFlag === 4
                        ? _c("previewPygameShow", {
                            staticStyle: {
                              height: "calc(100vh - 100px)",
                              "overflow-y": "auto",
                            },
                            attrs: {
                              sourceId: _vm.taskInfo.sourceId,
                              taskInfo: _vm.taskInfo,
                            },
                          })
                        : _vm._e(),
                      _vm.showTaskFlag === 5
                        ? _c("previewScratchJrShow", {
                            staticStyle: {
                              height: "calc(100% - 100px)",
                              "overflow-y": "auto",
                            },
                            attrs: {
                              sourceId: _vm.taskInfo.sourceId,
                              taskInfo: _vm.taskInfo,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.videoList.length === 0
                    ? _c(
                        "el-row",
                        {
                          staticStyle: {
                            "text-align": "center",
                            "margin-top": "18%",
                          },
                        },
                        [_vm._v(" 暂无任务 ")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm.courseStatus === "未上课"
            ? _c(
                "el-row",
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: {
                        "text-align": "center",
                        "margin-top": "18%",
                      },
                    },
                    [_vm._v(" 未上课状态不能查看任务 ")]
                  ),
                ],
                1
              )
            : _vm.courseStatus === "已上课"
            ? _c(
                "el-row",
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: {
                        "text-align": "center",
                        "margin-top": "18%",
                      },
                    },
                    [_vm._v(" 已上课状态不能查看任务 ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "我是标题",
            visible: _vm.drawer,
            "with-header": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { padding: "15px" } }, [
            _c(
              "div",
              {
                staticClass: "titleName",
                staticStyle: { "font-size": "14px", "margin-top": "15px" },
              },
              [_vm._v(" 学员列表 ")]
            ),
            _c(
              "div",
              { staticStyle: { "margin-top": "15px", "overflow-y": "auto" } },
              [
                _vm._l(_vm.lessonStudentList, function (item, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      _c(
                        "el-row",
                        { staticClass: "studentitem" },
                        [
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(item.name) +
                                  "[" +
                                  _vm._s(item.loginName) +
                                  "] "
                              ),
                            ]),
                          ]),
                          _c("el-col", { attrs: { span: 6 } }, [
                            item.isAttend === "是"
                              ? _c("span", { staticClass: "customButtwo" }, [
                                  _vm._v(" 已签到 "),
                                ])
                              : _c("span", { staticClass: "customButone" }, [
                                  _vm._v(" 未签到 "),
                                ]),
                          ]),
                          _c("el-col", { attrs: { span: 6 } }, [
                            item.isLogin === "是"
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "customButtwo",
                                    staticStyle: { "margin-left": "15px" },
                                  },
                                  [_vm._v(" 在线 ")]
                                )
                              : _c(
                                  "span",
                                  {
                                    staticClass: "customButone",
                                    staticStyle: { "margin-left": "15px" },
                                  },
                                  [_vm._v(" 离线 ")]
                                ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                _c(
                  "div",
                  { staticStyle: { "margin-top": "50px" } },
                  [
                    _vm.currentMenuState === "Y"
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.openStudentTask },
                          },
                          [_vm._v("关闭目录 ")]
                        )
                      : _vm._e(),
                    _vm.currentMenuState === "N" ||
                    _vm.currentMenuState === null
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.openStudentTask },
                          },
                          [_vm._v("显示目录 ")]
                        )
                      : _vm._e(),
                    _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content:
                            "当无法给学员推送任务且无法修复互动课堂时，可以给学员显示任务目录，使学员可自主打开任务内容",
                          placement: "right",
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-question",
                          staticStyle: {
                            "font-size": "18px",
                            "margin-left": "10px",
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ]),
        ]
      ),
      _c("summitrecord", {
        attrs: {
          show: _vm.recordDialog,
          taskID: _vm.currenttaskID,
          lessonId: _vm.lessonId,
        },
        on: {
          close: function ($event) {
            _vm.recordDialog = false
          },
        },
      }),
      _c("pythonRecord", {
        attrs: {
          show: _vm.pythonRecordDialog,
          currenttype: _vm.taskInfo.type,
          lessonId: _vm.lessonId,
          taskId: _vm.currenttaskID,
        },
        on: {
          close: function ($event) {
            _vm.pythonRecordDialog = false
          },
        },
      }),
      _c("scratchjrRecord", {
        attrs: {
          show: _vm.scratchjrRecordDialog,
          currenttype: _vm.taskInfo.type,
          lessonId: _vm.lessonId,
          taskId: _vm.currenttaskID,
        },
        on: {
          close: function ($event) {
            _vm.scratchjrRecordDialog = false
          },
        },
      }),
      _c("scratchRecord", {
        attrs: {
          show: _vm.scratchRecordDialog,
          taskID: _vm.currenttaskID,
          classID: _vm.classId,
          lessonId: _vm.lessonId,
        },
        on: {
          close: function ($event) {
            _vm.scratchRecordDialog = false
          },
        },
      }),
      _c("commitrecord", {
        attrs: {
          show: _vm.OjRecordDialog,
          contestId: _vm.taskInfo.sourceId,
          lessonId: _vm.lessonId,
        },
        on: {
          close: function ($event) {
            _vm.OjRecordDialog = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }